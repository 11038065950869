@import 'src/scss/base';

.scorebar {
  height: px2rem(8);
  width: 100%;
  border-radius: 5px;
  background: linear-gradient(345.96deg, #ff6145 0%, #f8c495 52%, #f2f2f2 100%);
  position: relative;

  @include lg {
    height: px2rem(11);
  }
}

.scorebar__score {
  width: px2rem(23);
  height: px2rem(23);
  border-radius: 50%;
  position: absolute;
  top: px2rem(-8);

  img {
    width: 100%;
  }

  @include lg {
    width: px2rem(34);
    height: px2rem(34);
    top: px2rem(-13);
  }
}

.scorebar--in-modal {
  @include lg {
    height: px2rem(8);

    .scorebar__score {
      top: px2rem(-8);
      width: px2rem(23);
      height: px2rem(23);
    }
  }
}
