@import 'src/scss/base';

.question {
  padding: px2rem(15) px2rem(10) px2rem(50);
}

.question__answers {
  max-width: px2rem(300);
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include md {
    max-width: px2rem(420);
  }

  @include lg {
    max-width: px2rem(925);
  }
}

.question__answers--less-then-tree {
  justify-content: flex-start;
  margin: unset;

  @include md {
    margin: 0 auto;
  }

  @include lg {
    justify-content: flex-start;
    margin: 0 auto;

    .answer-card:nth-child(2) {
      margin-left: px2rem(30);
    }
  }
}
