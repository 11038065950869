@import 'src/scss/base';

.recorder__video-wrapper {
  width: 100%;
  margin: 0 auto;
  background-color: $color-black;

  button {
    font-family: 'Aribau Grotesk';
  }

  @include lg {
    width: px2rem(400);
  }
}
