@import 'src/scss/base';

.wrapper {
  position: relative;
}

.button {
  width: 100%;
  @include button-default;
  padding: $spacing-24 0;
  color: $color-blacky;
  display: flex;
  justify-content: space-between;
  gap: 8px;

  @include lg {
    padding: 0;
    flex-direction: row-reverse;
    justify-content: start;
    gap: 16px;
  }
}

.textWrapper {
  text-align: start;
}

.text {
  color: $color-blacky;
}

.error {
  @include caption;
  position: absolute;
  bottom: -36px;
  color: $color-warning;
  width: 100%;
  height: 32px;
  word-wrap: break-word;

  &:disabled {
    color: $color-black;
    opacity: 0.38;
  }
}
