@import 'src/scss/base';

.stauffacher-block {
  background: $color-gradient;
  margin: px2rem(55) 0 px2rem(45) 0;
  border-radius: px2rem(16);
  color: $color-white;
}

.stauffacher-block__content {
  padding: px2rem(32);
  text-align: left;
}

.stauffacher-block__title {
  @include font-aribau-bold;

  font-size: px2rem(16);
  line-height: px2rem(20);
}

.stauffacher-block__text {
  @include font-aribau;

  margin-top: px2rem(32);
  font-size: px2rem(14);
  line-height: 140%;
}
