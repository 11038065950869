@import 'src/scss/base';

.accordion {
  position: relative;
  flex: 1;
  border: 1px solid $color-grey;
  border-radius: $spacing-8;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: $spacing-16 $spacing-24;

  @include md {
    padding: $spacing-24;
  }

  @include lg {
    cursor: pointer;
  }
}

.headerWrapper {
  display: flex;
  align-items: center;
}

.text {
  margin-top: $spacing-8;
}

.infoIcon {
  @include button-default;
}

.arrowDown {
  transition: all 0.5s ease;
}

.arrowUp {
  transform: rotate(-180deg);
  transition: all 0.5s ease;
}

.content {
  overflow: hidden;
  transition: max-height 0.5s ease-in-out;
}

.accordionType {
  margin-bottom: $spacing-4;
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  width: fit-content;
}

.title {
  margin-right: $spacing-4;
}
