@import 'src/scss/base';

.my-health-header-navbar {
  display: none;

  @include lg {
    display: flex;
    align-items: center;
    gap: $spacing-40;
  }
}

.my-health-header-navbar__link {
  @include button-default;
  color: $color-blacky;
  position: relative;
}

.my-health-header-navbar__active-link {
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}

.my-health-header-navbar__bubble {
  width: $spacing-16;
  height: $spacing-16;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gradient;
  -webkit-text-fill-color: $color-white;
  position: absolute;
  right: -10px;
  top: -10px;
}

.my-health-header-navbar__number-of-unviewed-results {
  font-size: 10px;
  font-weight: 400;
}
