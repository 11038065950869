@import 'src/scss/base';

.header {
  height: px2rem(50);
  background-color: $color-white;
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  padding: 0 px2rem(12);
  z-index: 10;

  @include md {
    position: relative;
    height: px2rem(80);
    padding: 0 px2rem(15);
  }

  @include lg {
    position: relative;
    height: px2rem(100);
    padding: 0 px2rem(5);
  }
}

.header--scrolled {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

.header__content {
  padding-top: px2rem(6);
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 100%;

  @include md {
    padding-top: px2rem(0);
  }

  @include lg {
    max-width: px2rem(925);
    margin: 0 auto;
  }
}

.header__logo-wrapper {
  width: px2rem(146);
  height: px2rem(36);
  position: absolute;
  left: 50%;

  @include md {
    width: px2rem(200);
    height: px2rem(56);
  }

  @include lg {
    width: px2rem(240);
    height: px2rem(72);
  }
}

.header__logo {
  width: 100%;
  height: 100%;
  position: relative;
  left: -50%;
}

.header__close-button {
  width: px2rem(34);
  height: px2rem(34);
  z-index: 1;

  svg {
    font-size: px2rem(34);
    fill: $color-purple;
  }

  .no-touchevents &:hover {
    svg {
      fill: $color-orange;
    }
  }

  @include lg {
    position: absolute;
    top: px2rem(20);
    right: px2rem(20);
  }
}

.header__reset-button {
  height: px2rem(24);
  width: px2rem(24);
  margin-right: px2rem(10);
}

.header__save_button {
  visibility: hidden;
  height: px2rem(24);
  width: px2rem(24);
}

.header__save-icon {
  fill: $color-purple !important;
}

.header__revert-icon {
  fill: $color-purple;
}

.header__continue-later-button {
  visibility: hidden;
}

.header__right-buttons {
  display: flex;
  align-items: center;
  margin-right: px2rem(-10);

  @include lg {
    margin-right: 0;
  }

  .dropdown {
    margin-right: px2rem(5);

    @include md {
      margin-right: px2rem(20);
    }
  }
}

.header__language-dropdown {
  @include lg {
    margin-right: px2rem(80);
  }

  @include xl {
    margin-right: 0;
  }
}
