@import 'src/scss/base';

.recommendation {
  max-width: px2rem(925);
  margin: 0 auto px2rem(30);

  @include sm-only {
    .carousel__dots {
      display: none !important;
    }
  }

  @include lg {
    padding-top: px2rem(15);
  }
}

.recommendation__content {
  display: flex;

  @include lg {
    justify-content: space-between;
  }
}

.recommendation__content--less-then-three {
  justify-content: flex-start;
}
