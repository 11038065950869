@import 'src/scss/base';

.reset-password-form__buttons-wrapper {
  display: flex;
  justify-content: flex-end;

  &--error {
    margin-top: px2rem(75);

    @include lg {
      margin-top: px2rem(80);
    }
  }
}