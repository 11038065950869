@import 'src/scss/base';

.product {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid $color-dark-grey-2;
}

.product__content {
  display: flex;

  @include lg {
    padding: px2rem(15) 0;
  }
}

.product__checkbox-and-image-wrapper {
  display: flex;
}

.product__image {
  display: flex;
  align-items: center;
  width: 80px;
  margin-left: px2rem(5);

  img {
    width: 100%;
  }

  @include lg {
    width: px2rem(140);
    margin-left: px2rem(25);
    margin-right: px2rem(25);
  }
}

.product__image--addon {
  @include lg {
    width: px2rem(90);
  }
}

.product__toogle-button {
  margin-left: auto;
  background: none;
  border: none;

  svg {
    width: px2rem(15);
    height: px2rem(15);
  }

  &--open {
    transform: rotate(180deg);
    padding-top: px2rem(8);
  }

  @include lg {
    display: none;
  }
}

.product__toogle-content {
  overflow: hidden;
  max-height: 0;
  transition: all 0.35s;
  padding: 0;
  text-align: left;
  display: flex;
  flex-direction: column;

  .product__learn-more {
    display: block;
  }

  &--open {
    max-height: 100vh;
    padding: px2rem(5) 0 px2rem(15);
  }

  @include lg {
    display: none;
  }
}

.product__description {
  @include font-arial;
  font-size: px2rem(12);
  text-align: left;
  display: block;

  @include md {
    font-size: px2rem(14);
  }
}

.product__title-and-price-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  @include lg {
    justify-content: flex-start;
    flex-basis: 28%;

    &--addon {
      justify-content: center;
    }
  }
}

.product__title-and-price-wrapper--addon {
  @include lg {
    justify-content: center;
    margin-right: px2rem(20);
  }

  .product__price-wrapper {
    @include lg {
      margin-bottom: 0;
    }
  }
}

.product__title {
  @include font-arial-bold;
  color: $color-purple;
  font-size: px2rem(14);
  text-align: left;

  @include lg {
    font-size: px2rem(17);
    margin-bottom: px2rem(7);
  }
}

.product__price-wrapper {
  @include font-arial;
  font-size: px2rem(12);

  @include lg {
    margin-bottom: px2rem(15);
  }
}

.product__learn-more {
  display: block;
  background-color: transparent;
  border: 1px solid $color-purple;
  align-self: start;
  margin-top: px2rem(10);
  font-size: px2rem(13);
  height: px2rem(35);
  display: none;

  svg {
    margin-right: 0;
    margin-left: px2rem(10);
  }

  @include lg {
    display: block;
    align-self: center;
    margin-top: 0;
    height: px2rem(38);
    font-size: px2rem(14);
    padding: 0;
  }
}

.product__sold-out {
  @include font-arial-bold;
  font-size: px2rem(10);
  color: $color-orange;

  @include lg {
    font-size: px2rem(12);
  }
}

.product__title-price-description-wrapper {
  display: flex;
  margin-left: px2rem(10);

  .product__description {
    display: none;
  }

  @include lg {
    display: block;
    margin-right: px2rem(35);
    margin-left: 0;

    .product__description {
      display: flex;
      flex-basis: 72%;
      align-items: center;
    }
  }
}

.product__title-price-description-wrapper--addon {
  display: flex;
}

.product__price-wrapper--sold-out {
  opacity: 0.3;
}
