@import 'src/scss/base';

.tab {
  @include button-default;
  position: relative;
  padding: 6px 0;
  color: $color-blacky;
}

.activeTab {
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background: $color-gradient;
    position: absolute;
    bottom: 0;
    left: 0;
  }
}
