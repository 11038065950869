@import 'src/scss/base';

.link {
  color: $color-blacky;
  @include button-default;
  position: relative;

  @include lg {
    width: fit-content;
  }
}

.link--active {
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  p {
    font-weight: 500;
  }
}

.link--active::after {
  content: '';
  display: block;
  width: 100%;
  height: 2px;
  background: $color-gradient;
  position: absolute;
  bottom: 0;
  left: 0;

  @include md {
    content: none;
  }
}

.link__text {
  padding: 6px 0;
}
