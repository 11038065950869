@import 'src/scss/base';

.wrapper {
  color: $color-blacky;
}

.title {
  margin-bottom: $spacing-16;
}

.checkboxWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-8;
}

.checkboxContent {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.checkbox {
  appearance: none;
  width: 24px;
  height: 24px;
  margin: 0;
  border: 2px solid $color-success;
  border-radius: 5px;

  &:checked {
    border: none;
    background-image: url('../../assets/svg/checkbox-success.svg');
    background-position: center;
  }
}
