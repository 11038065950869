@import 'src/scss/base';

.slide-content {
  position: relative;
  left: 50%;
  animation: slideIn 300ms forwards;
  overflow-x: hidden;
  width: calc(100% + 40px);
  margin-left: px2rem(-20);
  padding: 0 px2rem(20);

  @include md {
    padding: 0 px2rem(20);
  }
}

.slide-content__children {
  z-index: 1;
  position: relative;
  top: 0;
}

.slide-content__prev-children {
  position: absolute;
  width: calc(100% - 40px);
  height: 100%;
  top: 0;
}

@keyframes slideIn {
  100% {
    left: 0;
  }
}
