@import 'src/scss/base';

.wrapper {
  color: $color-grey-darker;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-grey-light;
  padding: $spacing-24 0;
}

.link {
  @include button-default;
  width: 100%;
  border-top: 1px solid $color-grey-light;
  padding: $spacing-24 0;
}

.iconWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.text {
  @include lg {
    @include body-1;
  }
}
