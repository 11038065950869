@import 'src/scss/base';

.recommended-actions-ctas {
  padding: 0 $spacing-24;
}

.recommended-actions-ctas__content {
  border-top: 1px dashed $color-grey;
  padding: $spacing-16 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: $spacing-8;

  &.recommended-actions-ctas__content-without-border {
    border: none;
    padding: 0;
  }

  @include md {
    flex-direction: row;
  }
}
