@import 'src/scss/base';

.eligibility-for-immunotherapy {
  color: $color-blacky;
}

.eligibility-for-immunotherapy__description {
  position: relative;
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.eligibility-for-immunotherapy__eligibility-panel-wrapper {
  margin-top: $spacing-16;
  display: flex;
  flex-direction: column;
  gap: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
    gap: $spacing-24;
  }
}

.eligibility-for-immunotherapy__immunotherapy {
  border-bottom: 1px dashed $color-grey;
  padding-bottom: $spacing-16;
  @include md {
    padding-bottom: $spacing-24;
  }
}

.eligibility-for-immunotherapy__info-icon {
  @include button-default;
  position: absolute;
  bottom: -5px;
}
