@import 'src/scss/base';

.enter-password-form__buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.enter-password-form__back-button {
  min-width: px2rem(100);
  
  svg {
    margin-right: 0;
    margin-left: unset;
  }
}

.enter-password-form__text {
  @include font-aribau;
  font-size: px2rem(14);
  color: #848484;
}

.enter-password-form__button {
  @include font-aribau-bold;
  font-size: px2rem(14);
  color: $color-purple;
  background: none;
  border: none;
  
  .no-touchevents & {
    cursor: pointer;
  }

  .no-touchevents &:hover {
    text-decoration: underline;
  }
}

.enter-password-form__text {
  margin-top: px2rem(25);
  text-align: center;
}