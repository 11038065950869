@import 'src/scss/base';

.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black, 0.6);
  z-index: 999;

  &.open {
    display: block;
  }
}

.wrapper {
  padding: 80px 24px 24px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  overflow-y: auto;
  border-radius: 28px 28px 0px 0px;
  background: $color-white;
  z-index: 1000;
  box-shadow: 0px 0px 10px 2px $color-black;
  transition: transform 0.2s ease-in-out;
  transform: translateY(100%);

  @include md {
    width: 464px;
    height: fit-content;
  }

  &.transform {
    transform: translateY(0);

    @include md {
      transform: translate(-50%, -50%);
    }
  }

  @include md {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 80px 48px 72px 48px;
    border-radius: 28px;
    overflow-y: hidden;
  }
}

.icon {
  cursor: pointer;
  position: absolute;
  top: 32px;
  right: 32px;
}
