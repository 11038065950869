@import 'src/scss/base';

.wrapper {
  padding: 0 $spacing-24;
  color: $color-blacky;
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  @include lg {
    padding: 0;
    gap: $spacing-48;
  }
}

.title {
  @include lg {
    @include subtitle-1;
  }
}
