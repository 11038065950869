@import 'src/scss/base';

.risks {
  max-width: px2rem(955);
  margin: 0 auto;
}

.risks__content {
  display: flex;

  @include lg {
    justify-content: space-between;
  }
}

.risks__title-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: px2rem(20) 0 px2rem(2);

  @include lg {
    margin: px2rem(20) 0;
  }
}

.risks__title {
  @include font-aribau-bold;
  color: $color-orange;
  font-size: px2rem(22);
  line-height: px2lineheight(22, 29);
  letter-spacing: 0.3px;

  @include lg {
    font-size: px2rem(28);
    line-height: px2lineheight(28, 36);
    letter-spacing: 0.38px;
  }
}

.risks__title-button {
  font-family: 'Arial';
  font-weight: 700;
  text-decoration: underline;
  border: 0;
  background: none;
  cursor: pointer;
  color: $color-purple;
  margin-top: px2rem(2);
  margin-left: px2rem(5);
  font-size: px2rem(14);
  line-height: px2lineheight(14, 16);

  @include lg {
    margin-top: px2rem(5);
    margin-left: px2rem(10);
  }
}
