@import 'src/scss/base';

.form-wrapper {
  width: 100%;
  background-color: $color-white;
  border-radius: 10px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.22);
  position: relative;
  margin-top: px2rem(25);
  margin-bottom: px2rem(40);

  @include md {
    width: 80%;
    margin: 0 auto;
  }

  @include lg {
    display: flex;
    width: 100%;
    max-width: px2rem(920);
  }
}

.form-wrapper__image {
  width: 100%;
  display: block;
}

.form-wrapper__image-wrapper {
  @include lg {
    flex-basis: 50%;
  }
}

.form-wrapper__content-wrapper {
  padding: px2rem(20) px2rem(20) px2rem(25);

  @include lg {
    position: relative;
    flex-basis: 50%;
    padding: px2rem(50) px2rem(70);
  }

  &--full-width {
    flex-basis: 100%;
  }
}

.form-wrapper__language-dropdown {
  position: absolute;
  right: 0;
  top: px2rem(-40);

  @include lg {
    top: px2rem(-45);
  }
}