@import 'src/scss/base';

.recommendation-modal-product {
  position: relative;

  &:not(:last-child) {
    .recommendation-modal-product__divider--bottom {
      display: none;
    }
  }
}

.recommendation-modal-product__content {
  padding: px2rem(20) 0;
  display: flex;

  @include sm {
    padding-left: px2rem(30);
  }

  @include md {
    padding-left: px2rem(20);
  }
}

.recommendation-modal-product__left-block {
  position: relative;
  margin-right: px2rem(30);

  @include sm {
    margin-right: px2rem(50);
  }
}

.recommendation-modal-product__image-wrapper {
  width: px2rem(120);
  height: px2rem(120);
  position: relative;

  img {
    width: 100%;
  }
}

.recommendation-modal-product__price {
  position: absolute;
  bottom: px2rem(-10);
  right: px2rem(-10);
  width: px2rem(60);
  height: px2rem(60);
}

.recommendation-modal-product__title {
  @include font-aribau-bold;
  color: $color-purple;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
  display: block;
}

.recommendation-modal-product__text {
  @include font-arial-bold;
  color: $color-black;
  font-size: px2rem(13);
  line-height: px2lineheight(13, 15);
  display: block;
  padding: px2rem(6) 0 px2rem(10);
}

.recommendation-modal-product__list {
  @include list-reset;
}

.recommendation-modal-product__illness-title {
  @include font-aribau;
  color: $color-black;
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  display: block;
}

.recommendation-modal-product__illness-risk {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);

  &--6,
  &--5 {
    color: $color-orange;
  }

  &--4,
  &--3,
  &--2 {
    color: $color-yellow;
  }

  &--1 {
    color: $color-light-blue;
  }
}

.recommendation-modal-product__right-block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.recommendation-modal-product__list-item {
  display: flex;
  flex-direction: column;
  margin-bottom: px2rem(6);
}

.recommendation-modal-product__divider {
  width: 200%;
  left: px2rem(-50);
  height: px2rem(1);
  background-color: $color-dirty-white;
  position: absolute;
}
