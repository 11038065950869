@import 'src/scss/base';

.table {
  width: 100%;
  min-height: px2rem(50);
  margin-top: px2rem(15);
}

.table__text {
  margin-bottom: px2rem(10);
}

.table__content {
  background-color: $color-light-grey-2;
}

.table__header {
  width: 100%;
  height: px2rem(35);
  background-color: $color-light-grey-3;
  display: flex;
  margin-bottom: px2rem(5);
}

.table__header-item {
  @include font-aribau;
  display: flex;
  font-size: px2rem(14);
  color: $color-black;
  width: 50%;
  align-items: center;
  padding-left: px2rem(15);
  max-width: 38%;
  width: 38%;

  @include md {
    width: px2rem(165);
    max-width: px2rem(165);
  }
}

.table__header-item--small {
  max-width: 24%;
  width: 24%;

  @include md {
    width: px2rem(165);
    max-width: px2rem(165);
  }
}

.table__row {
  display: flex;
  height: px2rem(35);
}

.table__row-item {
  @include font-aribau;
  display: flex;
  font-size: px2rem(14);
  color: $color-black;
  width: 50%;
  align-items: center;
  padding-left: px2rem(15);
  max-width: 38%;
  width: 38%;

  @include md {
    width: px2rem(165);
    max-width: px2rem(165);
  }
}

.table__row-item--small {
  max-width: 24%;
  width: 24%;

  @include md {
    width: px2rem(165);
    max-width: px2rem(165);
  }
}

.table__text {
  font-weight: bold;
}