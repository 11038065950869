@import 'src/scss/base';

.wrapper {
  width: 100%;
  height: 7px;
  border-radius: 16px;
  display: flex;
  gap: 2px;
  position: relative;
}

.indicatorWrapper {
  color: $color-white;
  position: absolute;
  top: -32px;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.indicator {
  width: 43px;
  height: 23px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.indicatorAfter {
  position: absolute;
  top: 22px;
  border-left: solid 5px transparent;
  border-right: solid 5px transparent;
}

.infiniteIcon {
  position: absolute;
  right: 0;
  top: 4px;
  width: 24px;
  height: 24px;

  & svg,
  path {
    width: 24px;
    height: 24px;
  }
}
