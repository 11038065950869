@import 'src/scss/base';

.test-results-navigation {
  color: $color-blacky;
  display: flex;
  flex-direction: column;
  gap: 11px;
}

.test-results-navigation__links-wrapper {
  padding: 0 $spacing-24;
  color: $color-blacky;
  overflow: scroll;
  display: flex;
  gap: 14px;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;

  @include md {
    padding: 0;
  }

  @include lg {
    margin-left: 20px;
    flex-direction: column;
    gap: 3px;
  }
}

.test-results-navigation__links-wrapper::-webkit-scrollbar {
  display: none;
}

.test-results-navigation__title {
  display: none;
  text-transform: uppercase;

  @include lg {
    margin-bottom: 8px;
    display: block;
  }
}
