@import 'src/scss/base';

.slider-prev-button {
  position: absolute;
  color: $color-white;
  top: 50%;
  left: px2rem(5);
  transform: translateY(-50%);
  z-index: 1;
  width: 50px;
  height: 50px;
  background: $color-orange;
  border: 2px solid $color-orange;
  border-radius: 50%;
  display: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;

  &:hover {
    background: $color-white;
    border-color: $color-white;
    color: $color-orange;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  @include lg {
    display: flex;
    top: calc((480px / 2));

    svg {
      width: px2rem(30);
      height: px2rem(30);
    }
  }
}
