@import 'src/scss/base';

.button {
  @include button-default;
  width: 100%;
  position: relative;
  border-top: 1px solid $color-grey;
  color: $color-grey-darker;
  display: flex;
  gap: $spacing-8;
  align-items: center;
  padding: $spacing-16 $spacing-24;
}
