@import 'src/scss/base';

.modal {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 100;
}

.modal__content {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background: $color-white;
  padding: 0 px2rem(20) px2rem(30);
  position: absolute;
  right: -100%;
  top: 0;
  animation: slideFromRight 500ms forwards;

  @include lg {
    padding: 0 px2rem(80) px2rem(60);
  }

  @include xl {
    padding: 0 px2rem(80);
  }
}

.modal__content--exiting {
  right: 0;
  animation: slideToRight 500ms forwards;
}

.modal__content--small {
  padding: 0 px2rem(50) px2rem(30);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);

  @include md {
    padding: 0 px2rem(60) px2rem(60);
    width: px2rem(340);
  }
}

.modal__close-button {
  position: absolute;
  top: px2rem(10);
  right: px2rem(5);
  color: $color-purple;
  cursor: pointer;
  border: 0;
  background: none;
  z-index: 10;

  .no-touchevents &:hover {
    color: $color-orange;
  }

  @include lg {
    top: px2rem(20);
    right: px2rem(20);
  }
}

.modal__close-button--right {
  left: unset;
  right: px2rem(12);
}

.modal__close-button-icon {
  font-size: px2rem(36) !important;
}

@keyframes slideFromRight {
  100% {
    right: 0;
  }
}

@keyframes slideToRight {
  100% {
    right: -100%;
  }
}
