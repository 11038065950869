@import 'src/scss/base';

.wrapper {
  position: relative;
}

.text {
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.buttonWrapper {
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-16;
  }
}
