@import 'src/scss/base';

.loader-screen {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.loader-screen__content-wrapper {
  margin: 0 px2rem(10);
  padding: px2rem(28);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  max-width: px2rem(920);
  overflow: hidden;
  padding-top: 0;

  @include md {
    margin: 0 px2rem(30);
    padding: px2rem(35);
    padding-top: 0;
  }

  @include lg {
    margin: 0 auto;
    padding: px2rem(45) px2rem(40);
    padding-top: 0;
  }
}

.loader-screen__loader {
  width: 100%;
  text-align: center;
}

.loader-screen__content {
  text-align: center;
}

.loader-screen__text {
  @include font-aribau-bold;

  font-size: px2rem(20);
  color: $color-purple;
  max-width: px2rem(350);

  @include lg {
    font-size: px2rem(22);
    display: block;
    margin: 0 auto;
  }
}