@import 'src/scss/base';

.wrapper {
  color: $color-blacky;
  background: $color-grey-light;
  padding: $spacing-16;
  border-radius: $spacing-8 $spacing-8 $spacing-8 0;

  @include md {
    padding: $spacing-24;
  }

  @include lg {
    padding: $spacing-32;
  }
}

.text {
  ul {
    padding: 0;
    padding-left: $spacing-16;
  }
}

.imageWrapper {
  margin-top: $spacing-24;
  display: flex;
  align-items: center;
  gap: $spacing-8;

  @include lg {
    gap: $spacing-16;
  }
}
