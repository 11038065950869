@import 'src/scss/base';

.accordion {
  display: flex;
  height: auto;

  @include lg {
    cursor: pointer;
  }

  &:last-child {
    .accordion__content-wrapper {
      border-bottom: 0;
    }
  }
}

.accordion__progress-line {
  width: 1px;
  height: auto;
  background-color: $color-light-grey-3;
  position: relative;
  margin-right: px2rem(15);

  @include lg {
    margin-right: px2rem(30);
  }
}

.accordion__check-circle {
  width: px2rem(18.5);
  height: px2rem(18.5);
  border-radius: 50%;
  background-color: $color-white;
  margin-right: px2rem(10);
  position: absolute;
  top: calc(50% - 10px);
  left: px2rem(-25);
  z-index: 1;

  @include lg {
    left: px2rem(-41);
    width: px2rem(20.5);
    height: px2rem(20.5);
  }
}

.accordion__content-wrapper {
  border-bottom: 1px solid $color-light-grey-3;
  padding: px2rem(20) 0;
  flex-grow: 1;
  position: relative;
}

.accordion__content {
  height: 0;
  overflow: hidden;

  &--open {
    height: auto;
    padding-top: px2rem(20);
  }
}

.accordion__title-wrapper {
  position: relative;
}

.accordion__arrow {
  width: px2rem(16);
  height: px2rem(16);
  border-radius: 50%;
  position: absolute;
  right: 0;
  top: calc(50% - 8px);

  @include lg {
    width: px2rem(20);
    height: px2rem(20);
    top: calc(50% - 10px);
  }
}

.accordion__title {
  @include font-aribau-bold;

  font-size: px2rem(16);
  color: $color-purple;
  display: block;
  margin-right: px2rem(15);

  @include lg {
    font-size: px2rem(18);
  }
}

.accordion__subtitle {
  @include font-aribau;

  font-size: px2rem(14);
  color: $color-orange;
}

.accordion__overflow-line {
  position: absolute;
  // 20px is padding
  height: calc(50% + 20px);
  width: px2rem(3);
  left: px2rem(-17);
  background-color: $color-white;

  &--first {
    top: px2rem(-20);
  }

  &--last {
    top: px2rem(45);
    height: calc(100% - 20px);
  }

  @include md {
    &--last {
      top: px2rem(35);
    }
  }

  @include lg {
    left: px2rem(-32);
  }
}
