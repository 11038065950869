@import 'src/scss/base';

.picker {
  align-items: center;
  border: solid 1px $color-light-grey-3;
  display: flex;
  height: 60px;
  justify-content: center;
  margin-bottom: 25px;
  position: relative;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  @include lg {
    cursor: pointer;
    margin-bottom: 0;
    width: px2rem(200);

    &:not(:last-child) {
      margin-right: px2rem(20);
    }
  }

  &--disabled {
    @include lg {
      cursor: not-allowed;
    }
  }
}

.picker__text {
  @include font-aribau;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 21);
  color: $color-light-grey-3;

  &--black {
    color: $color-black;
  }

  @include lg {
    margin-right: px2rem(10);
  }
}

.picker__list {
  background-color: $color-white;
  background: $color-white;
  border-radius: 5px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.22);
  left: -1px;
  position: absolute;
  top: 65px;
  width: calc(100% + 2px);
  z-index: 1;

  @include lg {
    width: px2rem(260);
  }
}

.picker__list-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 10px 10px;
}

.picker__days-items {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 0px 5px 10px;
  // justify-content: space-between;
}

.picker__arrow-down {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid $color-purple;
  height: 0;
  position: absolute;
  right: 15px;
  top: 28px;
  width: 0;

  &--disabled {
    border-top: 6px solid $color-light-grey-3;
  }
}

.picker__arrow {
  border-top: 2px solid $color-purple;
  border-left: 2px solid $color-purple;
  width: 10px;
  height: 10px;
  display: block;
  transform: rotate(-45deg);

  &--right {
    transform: rotate(135deg);
  }
}

.picker__list-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid $color-light-grey-3;
  padding: 10px 10px 5px;
}

.picker__month-list-header {
  align-items: center;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid $color-light-grey-3;
  padding: 10px 10px 5px;
  height: 40px;
}

.picker__current-values {
  @include font-aribau-bold;
  font-size: px2rem(16);
  line-height: px2lineheight(16, 19);
  color: $color-purple;
}

.picker__button {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 18);
  border: 0;
  background: none;
  color: $color-black;
  height: 34px;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 25%;
  flex-basis: 25%;
}

.picker__change-button {
  height: 24px;
  border: 0;
  background: none;
}

.picker__button-selector {
  border-radius: 50%;
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--selected {
    background: $color-purple;
    color: $color-white;
  }
}

.picker__days-list {
  padding: 10px 5px;
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 18);
  color: $color-light-grey-3;
  display: flex;
  justify-content: space-between;
}

.picker__day {
  width: 14%;
  flex-basis: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker__day-button {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 18);
  border: 0;
  background: none;
  color: $color-black;
  height: 34px;
  padding: 0;
  display: flex;
  justify-content: center;
  width: 14%;
  flex-basis: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.picker__day-button-placeholder {
  width: 14%;
  flex-basis: 14%;
}
