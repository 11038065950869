@import 'src/scss/base';

.dashboard__content {
  position: relative;
  display: flex;
}

.dashboard__left-block {
  margin-top: $spacing-24;
  width: 100%;

  @include lg {
    margin-top: $spacing-64;
  }

  @media screen and (min-width: 1440px) {
    padding-bottom: $spacing-56;
    width: 70%;
  }
}

.dashboard__right-block {
  display: none;

  @media screen and (min-width: 1440px) {
    display: block;
    width: 30%;
    position: fixed;
    right: 0;
    height: calc(100vh - 100px);
    top: 100px;
  }
}

.dashboard__title {
  padding: 0 $spacing-24;
  margin-bottom: $spacing-40 !important;

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
  }
}

.dashboard__navigation {
  padding: 0 $spacing-24;

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
  }
}

.dashboard__products-list {
  padding: 0 $spacing-24;

  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
  }
}

.dashboard__loader-wrapper,
.dashboard__error-wrapper {
  display: flex;
  justify-content: center;
  padding-top: px2rem(100);
}

.dashboard__error-wrapper {
  @include font-aribau-bold;
  font-size: px2rem(30);
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
  z-index: 1;
  position: relative;
}

.dashboard__health-knowledge-mobile {
  @media screen and (min-width: 1440px) {
    display: none;
  }
}

.dashboard__no-cards {
  @include lg {
    padding: 0;
    width: 740px;
    margin: 0 auto;
  }
}
