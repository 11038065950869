@import 'src/scss/base';

.treatment-plan {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.treatment-plan__content-wrapper {
  margin: 0 px2rem(10);
  padding: px2rem(28);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  max-width: px2rem(920);
  overflow: hidden;

  @include md {
    margin: 0 px2rem(30);
    padding: px2rem(35);
  }

  @include lg {
    margin: 0 auto;
    padding: px2rem(45) px2rem(40);
  }
}

.treatment-plan__right-content {
  @include md {
    flex: 1;
    padding-left: px2rem(20);
  }
}

.treatment-plan__title-small {
  @include font-aribau-bold;

  font-size: px2rem(22);
  color: $color-dark-orange;
  margin-bottom: px2rem(5);
  display: block;
  text-align: center;

  @include md {
    display: none;
  }
}

.treatment-plan__title-large {
  display: none;

  @include md {
    @include font-aribau-bold;

    display: flex;
    flex-wrap: wrap;
    font-size: px2rem(28);
    color: $color-dark-orange;
    margin-bottom: px2rem(25);
  }
}

.treatment-plan__loader {
  width: 100%;
  text-align: center;
}
