@import 'src/scss/base';

.wrapper {
  display: flex;
  align-items: center;
  padding: $spacing-8 0;
  color: $color-blacky;
}

.title {
  text-wrap: nowrap;
  margin-right: $spacing-16;
  text-transform: uppercase;
  display: block;
}

.line {
  width: 100%;
  background: $color-grey;
  height: 1px;
}
