@import 'src/scss/base';

.country-dropdown {
  border: 1px solid $color-grey;
  display: flex;
  align-items: center;
  padding: $spacing-24;
  border-radius: $spacing-8;
  cursor: not-allowed;
}

.country-dropdown__select {
  @include body-1;
  width: 100%;
  @include button-default;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  color: $color-grey-darker;
  cursor: not-allowed;
}
