@import 'src/scss/base';

.carousel {
  margin-left: px2rem(-12);
  width: calc(100% + 24px);

  .slick-track {
    display: flex !important;
  }

  .slick-disabled {
    display: none !important;
  }

  .slick-slide {
    height: inherit !important;
    display: flex !important;
    align-items: stretch;

    & > div {
      display: flex !important;
      align-items: stretch;
      width: 100%;
    }
  }
}

.carousel__dots {
  @include list-reset;
  display: flex !important;
  justify-content: center;
  margin: px2rem(5) 0 px2rem(30);

  button {
    @include button-default;
    font-size: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  li {
    height: px2rem(3);
    width: px2rem(25);
    border-radius: px2rem(5);
    background-color: $color-white;
    margin-right: px2rem(15);
    position: relative;

    &:last-child {
      margin-right: px2rem(0);
    }
  }

  .slick-active {
    background-color: $color-purple-dark;
  }
}

.carousel--inModal {
  margin-left: px2rem(-20);
  width: calc(100% + 40px);

  .carousel__dots {
    margin-bottom: px2rem(5);
    margin-top: px2rem(20);

    li {
      background-color: $color-purple-dark;
    }

    .slick-active {
      background-color: $color-dirty-white;
    }
  }

  .slick-arrow {
    width: px2rem(58);
    height: px2rem(58);
    background-color: $color-light-grey;
    border-radius: 50%;

    &:before {
      content: '';
    }

    &:hover {
      background-color: $color-grey;
      opacity: 1;
    }

    &:focus {
      background-color: $color-grey;
      opacity: 1;
    }
  }

  .slick-prev {
    left: px2rem(-58);

    &:before {
      content: '';
      opacity: 1 !important;
      width: 18px;
      height: 3px;
      position: absolute;
      background: $color-white;
      top: calc(50% - 6px);
      left: px2rem(18);
      transform: rotate(-45deg);
    }

    &:after {
      content: '';
      opacity: 1 !important;
      width: 18px;
      height: 3px;
      position: absolute;
      background: $color-white;
      top: calc(50% + 5px);
      left: px2rem(18);
      transform: rotate(45deg);
    }
  }

  .slick-next {
    right: px2rem(-58);

    &:before {
      content: '';
      opacity: 1 !important;
      width: 18px;
      height: 3px;
      position: absolute;
      background: $color-white;
      top: calc(50% - 6px);
      right: px2rem(18);
      transform: rotate(45deg);
    }

    &:after {
      content: '';
      opacity: 1 !important;
      width: 18px;
      height: 3px;
      position: absolute;
      background: $color-white;
      top: calc(50% + 5px);
      right: px2rem(18);
      transform: rotate(-45deg);
    }
  }

  @include lg {
    width: 100%;
    margin-left: 0;
  }
}
