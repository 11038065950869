@import 'src/scss/base';

.accordion-text-content {
  display: flex;

  &:not(:last-child) {
    margin-bottom: px2rem(10);
    padding-bottom: px2rem(10);
    border-bottom: 1px solid $color-light-grey-3;
  }
}

.accordion-text-content__left-side {
  flex-basis: 45%;
  width: 45%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &--two-buttons {
    justify-content: space-between;
  }

  @include md {
    flex-basis: 30%;
    width: 30%;
  }

  @include lg {
    flex-basis: 20%;
    width: 20%;
  }
}

.accordion-text-content__right-side {
  display: flex;
  flex-direction: column;
  flex-basis: 55%;
  width: 55%;
  padding-left: px2rem(10);
  justify-content: center;

  &--two-buttons {
    justify-content: space-between;
  }

  @include md {
    padding-left: px2rem(30);
    flex-basis: 70%;
    width: 70%;

    &--two-buttons {
      justify-content: center;
    }
  }
}

.accordion-text-content__text {
  @include font-aribau;

  font-size: px2rem(12);

  @include lg {
    font-size: px2rem(14);
  }
}

.accordion-text-content__button {
  margin-top: px2rem(15);
  text-align: center;
  font-size: px2rem(12);

  @include md {
    margin-top: px2rem(25);
    width: fit-content;

    &--primary {
      margin-right: px2rem(15);

      .no-touchevents &:hover {
        border: 1px solid $color-orange;
      }
    }
  }

  @include lg {
    font-size: px2rem(14);
  }
}

.accordion-text-content__image {
  width: 100%;
}

.accordion-text-content__buttons-wrapper {
  display: flex;
}