@import 'src/scss/base';

.loader {
  display: inline-block;
  position: relative;
  width: px2rem(60);
  height: px2rem(38);
}

.loader div {
  position: absolute;
  top: px2rem(14);
  width: px2rem(10);
  height: px2rem(10);
  border-radius: 50%;
  background: $color-white;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.loader div:nth-child(1) {
  left: px2rem(6);
  animation: grow 0.6s infinite;
}

.loader div:nth-child(2) {
  left: px2rem(6);
  animation: move 0.6s infinite;
}

.loader div:nth-child(3) {
  left: px2rem(24);
  animation: move 0.6s infinite;
}

.loader div:nth-child(4) {
  left: px2rem(42);
  animation: shrink 0.6s infinite;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes move {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(px2rem(18), 0);
  }
}

@keyframes shrink {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
