@import 'src/scss/base';

.sectionTitle {
  position: absolute;
  margin-left: -8px;
  top: $spacing-8;
  color: $color-grey-dark;
}

.sectionTitleFirst {
  position: absolute;
  margin-left: 0;
  top: $spacing-8;
  color: $color-grey-dark;
}
