.playground {
  position: relative;
}

.playground__text {
  width: 500px;
  margin: 0 auto;
}

.playground__header {
  width: 100%;
  height: 200px;
  border: 10px solid burlywood;
  position: fixed;
  top: 0;
  left: 0;
  background-color: white;
  z-index: 100;
}

.playground__content-wrapper {
  display: flex;
  margin-top: 200px;
}

.playground__content {
  width: 75%;
  border-right: 1px solid black;
}

.playground__sidebar {
  width: 25%;
  border: 10px solid cornflowerblue;
  position: fixed;
  top: 200px;
  right: 0;
}

#test,
#pest {
  color: red;
}
