@import 'src/scss/base';

.css-support {
  color: $color-blacky;
  margin-top: $spacing-24;
  padding: 0 $spacing-24;

  @include md {
    margin-top: $spacing-40;
  }

  @include lg {
    padding: 0;
  }
}

.css-support__content {
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.css-support__text {
  margin-top: $spacing-16;
}

.css-support__url {
  @include button-default;
  padding-left: $spacing-4;
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;
}
