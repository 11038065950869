@import 'src/scss/base';

.risk-modal__content {
  margin-top: px2rem(10);
  display: flex;
  flex-direction: column;

  .slick-slide:first-child {
    .risk-modal-card {
      margin-left: px2rem(25) !important;
    }
  }

  @include lg {
    width: 100%;
    max-width: px2rem(1260);
    margin: px2rem(60) auto 0;
  }
}
