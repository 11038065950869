@import 'src/scss/base';

.confirm-email__text {
  @include font-aribau;
  font-size: px2rem(14);
  color: #848484;
}

.confirm-email__text-text {
  display: inline-block;
  margin-right: px2rem(5);
}

.confirm-email__button {
  @include font-aribau-bold;
  font-size: px2rem(14);
  color: $color-purple;
  background: none;
  border: none;
  display: inline;
  padding: 0;
  
  .no-touchevents & {
    cursor: pointer;
  }

  .no-touchevents &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: $color-dark-grey;
  }

  .no-touchevents &:disabled {
    cursor: not-allowed;
  }

  &:disabled:hover {
    text-decoration: none;
  }
}

.confirm-email__error {
  @include font-aribau;
  display: block;
  text-align: center;
  color: $color-red;
  margin-top: px2rem(20);
}