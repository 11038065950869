@import 'src/scss/base';

.contact-by-phone-accordion-content {
  padding: 0 $spacing-24 $spacing-16 $spacing-24;
}

.contact-by-phone-accordion-content__text--mobile {
  @include md {
    display: none;
  }
}

.contact-by-phone-accordion-content__text--desktop {
  display: none;

  @include md {
    display: block;
  }
}

.contact-by-phone-accordion-content__button {
  margin-top: $spacing-16;
  @include md {
    display: none;
    margin: 0;
  }
}
