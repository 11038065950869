@import 'src/scss/base';

.content {
  padding: px2rem(60) px2rem(12) px2rem(50);
  z-index: 1;
  position: relative;

  @include md {
    padding: 0;
    padding-bottom: px2rem(115);
  }
}

.content--with-sticky-title {
  // sum of header and title box heights
  padding-top: px2rem(103);
}

.content__loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
