@import 'src/scss/base';

.modalOverlay {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background: $color-white;
  width: 100%;
  height: auto;
  max-height: 100dvh;
  overflow-y: scroll;

  @include md {
    border-radius: 28px;
    width: 500px;
    height: auto;
    margin: 0 auto;
    max-height: calc(100vh - 60px);
  }
}

.content {
  position: relative;
}
