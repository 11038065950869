@import 'src/scss/base';

.action {
  @include lg {
    .background__elipse-wrapper {
      height: 100%;
    }
  }
}

.action__content {
  position: relative;
  z-index: 1;
  padding: px2rem(25) px2rem(15);

  @include lg {
    margin-top: px2rem(75);
  }
}

.action__inner-content {
  display: flex;
  justify-content: center;
  align-items: center;

  @include lg {
    min-height: px2rem(594);
  }
}

.action__error {
  text-align: center;
}

.action__icon {
  width: px2rem(50);
  margin-bottom: px2rem(20);

  @include lg {
    width: px2rem(70);
  }
}

.action__error-message {
  @include font-aribau;
  display: block;
  text-align: center;
  color: $color-black;
  font-size: px2rem(18);
}

.action__error-message--bold {
  @include font-aribau-bold;
  margin-top: px2rem(10);
}

.action__button {
  display: block;
  text-align: center;
  font-size: px2rem(12);
  margin: px2rem(15) auto 0;

  .no-touchevents &:hover {
    border-color: $color-orange !important;
  }

  @include md {
    margin-top: px2rem(25);
    width: fit-content;

    &--primary {
      margin-right: px2rem(15);
    }
  }

  @include lg {
    font-size: px2rem(14);
  }
}