@import 'src/scss/base';

.test-result {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.test-result__content-wrapper {
  margin: 0 px2rem(10);
  padding: px2rem(28);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  max-width: px2rem(920);
  overflow: hidden;

  @include md {
    margin: 0 px2rem(30);
    padding: px2rem(35);
  }

  @include lg {
    margin: 0 auto;
    padding: px2rem(45) px2rem(40);
  }
}

.test-result__image-wrapper {
  width: px2rem(180);
  height: px2rem(180);
  margin: 0 auto;
  margin-bottom: px2rem(10);

  @include md {
    margin: unset;
  }
}

.test-result__image {
  width: 100%;
}

.test-result__text {
  @include font-aribau;
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  display: block;
  text-align: left;
  color: $color-dark-grey;

  a {
    color: #554494;
  }

  h1,
  h2,
  h3 {
    margin-bottom: px2rem(10);
  }

  h4 {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
    margin-bottom: px2rem(10);
  }

  p {
    margin-bottom: px2rem(15);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-purple;
    }
  }

  ul {
    @include list-reset;
    margin-bottom: px2rem(20);
  }

  ol {
    margin: 0 0 px2rem(20) 0;
    padding-left: px2rem(20);
  }

  ol li {
    padding: px2rem(5) 0 0 0;
  }

  ul li {
    padding-left: px2rem(20);
    display: block;
    position: relative;

    &:before {
      content: '';
      border-radius: 50%;
      width: px2rem(8);
      height: px2rem(8);
      background: $color-purple;
      position: absolute;
      left: px2rem(5);
      top: px2rem(5);
    }
  }

  table {
    margin-bottom: px2rem(12);

    thead {
      text-align: left;

      th:first-child {
        font-weight: normal;
      }
    }

    td {
      padding: 0;

      &:first-child {
        min-width: px2rem(115);
      }

      &:nth-child(2) {
        font-weight: 700;

        em {
          font-style: normal;
          color: $color-red;
        }

        strong {
          color: $color-green;
        }
      }
    }
  }

  @include md {
    h4 {
      font-size: px2rem(16);
      line-height: px2lineheight(16, 21);
    }

    ul li {
      &:before {
        top: px2rem(5);
      }
    }

    table {
      td {
        &:first-child {
          min-width: px2rem(150);
        }
      }
    }
  }

  @include lg {
    p {
      margin-bottom: px2rem(20);
    }

    h4 {
      font-size: px2rem(18);
      line-height: px2lineheight(18, 23);
    }

    img {
      width: 80%;
      margin: 0 auto;
      display: block;
    }

    ul li {
      &:before {
        top: px2rem(4);
      }
    }

    table {
      margin-bottom: px2rem(20);

      td {
        &:first-child {
          min-width: px2rem(155);
        }
      }
    }
  }
}

.test-result__content {
  @include md {
    display: flex;
  }
}

.test-result__right-content {
  @include md {
    flex: 1;
    padding-left: px2rem(20);
  }
}

.test-result__title-small {
  @include font-aribau-bold;

  font-size: px2rem(22);
  color: $color-dark-orange;
  margin-bottom: px2rem(5);
  display: block;
  text-align: center;

  @include md {
    display: none;
  }
}

.test-result__title-large {
  display: none;

  @include md {
    @include font-aribau-bold;

    display: flex;
    flex-wrap: wrap;
    font-size: px2rem(28);
    color: $color-dark-orange;
    margin-bottom: px2rem(25);
  }
}

.test-result__download-wrapper {
  display: flex;
  flex-direction: column;
  margin-top: px2rem(30);
}

.test-result__download-title {
  @include font-aribau-bold;

  font-size: px2rem(14);
  color: $color-black;
}

.test-result__download-content {
  margin-top: px2rem(8);
  text-align: center;

  @include md {
    text-align: unset;
    display: flex;
    height: px2rem(40);
  }
}

.test-results__document-icon {
  fill: $color-purple;
  width: px2rem(20);
  height: px2rem(20);
  margin-right: px2rem(5);
}

.test-result__download-link {
  @include font-aribau;

  color: $color-black;
  font-size: px2rem(12);
  display: flex;
  align-items: center;
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: underline;

  @include md {
    margin-right: px2rem(10);
  }
}

.test-result__download-wrapper {
  margin-top: px2rem(38);
}

.test-result__error {
  @include font-aribau;

  color: $color-red;
  display: block;
  margin-top: px2rem(5);
}

.test-result__loader {
  width: 100%;
  text-align: center;
}

.test-result__medgate {
  margin-top: px2rem(50);
}
