@import 'src/scss/base';

.slider-screen {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.slider-screen__content-wrapper {
  margin: 0 px2rem(10);
  padding: px2rem(25);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  max-width: px2rem(920);
  overflow: hidden;

  @include md {
    margin: 0 px2rem(30);
    padding: px2rem(35);
  }

  @include lg {
    margin: 0 auto;
    padding: px2rem(45) px2rem(40);
  }
}
