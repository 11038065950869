@import 'src/scss/base';

.form-text {
  margin-top: px2rem(20);
  margin-bottom: px2rem(35);

  ul {
    padding-left: px2rem(16);
  }
}

.form-text__text {
  @include font-aribau;
  font-size: px2rem(14);
  color: #848484;
}