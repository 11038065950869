@import 'src/scss/base';

.risk-card {
  min-height: px2rem(268);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  width: 100%;
  margin: px2rem(20) px2rem(10);
  padding: px2rem(40) px2rem(15) px2rem(20);
  display: flex;

  @include sm {
    margin: px2rem(20) px2rem(15);
  }

  @include lg {
    min-height: px2rem(310);
    margin: px2rem(20) px2rem(17);
    padding: px2rem(46) px2rem(25) px2rem(30);
  }
}

.risk-card__icon {
  width: px2rem(52);
  height: px2rem(192);

  @include md {
    width: px2rem(60);
    height: px2rem(225);
  }
}

.risk-card__list {
  @include font-aribau-bold;
  @include list-reset;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);
  color: $color-purple;
  margin-top: px2rem(22);
  margin-left: px2rem(2);

  @include md {
    font-size: px2rem(14);
    margin-left: px2rem(10);
    line-height: px2lineheight(14, 18);
  }
}

.risk-card__list-item {
  &:not(:last-child) {
    margin-bottom: px2rem(18);
  }

  &:before {
    content: '';
    width: px2rem(8);
    height: px2rem(8);
    border-radius: 50%;
    background: $color-purple;
    display: inline-block;
    margin-right: px2rem(6);
  }
}

.risk-card__list-title {
  font-family: 'Aribau Grotesk';
  font-weight: bold;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
}

.risk-card__list-wrapper {
  padding-left: px2rem(10);
  padding-top: px2rem(10);

  @include md {
    padding-left: px2rem(15);
    padding-top: px2rem(25);
  }
}

.risk-card__list-wrapper--high {
  color: $color-orange;
}

.risk-card__list-wrapper--medium {
  color: $color-yellow;
}

.risk-card__list-wrapper--low {
  color: $color-light-blue;
}

.risk-card__image-wrapper {
  width: px2rem(140);
  height: px2rem(140);
}

.risk-card__image {
  width: 100%;
}

.risk-card__content {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.risk-card__title {
  @include font-aribau;
  font-size: px2rem(14);
  color: $color-black;
  margin-top: px2rem(45);
  display: block;
  position: relative;

  @include lg {
    font-size: px2rem(18);
    line-height: px2rem(23);
  }
}

.risk-card__info {
  margin-left: px2rem(5);
  width: px2rem(18);
  height: px2rem(18);
  position: absolute;
  right: px2rem(-22);
  top: px2rem(-5);

  @include lg {
    margin-bottom: px2rem(15);
  }
}

.risk-card__icon {
  font-size: px2rem(15) !important;
  fill: $color-disabled !important;
}
