@import 'src/scss/base';

.documents {
  margin-top: $spacing-24;
  border-top: 6px solid $color-grey-light;
  border-bottom: 6px solid $color-grey-light;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }

  @include lg {
    border: none;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: $spacing-24;
  }
}

.documents__title {
  display: none;
  color: $color-blacky;
  text-transform: uppercase;

  @include lg {
    display: block;
  }
}

.documents__download-buttons-wrapper {
  display: flex;
  flex-direction: column;
  padding: 0 $spacing-24;

  @include md {
    padding: 0;
  }

  @include lg {
    gap: $spacing-16;
  }
}

.documents__line {
  border-top: 1px dashed $color-grey;

  @include lg {
    border: none;
  }
}
