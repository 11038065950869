@import 'src/scss/base';

.wrapper {
  background: $color-white;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  border: 2px dashed $color-grey;
  border-radius: $spacing-16;
  max-width: 264px;
  padding: 105px 0 $spacing-16 0;

  @include lg {
    padding: 105px 0 $spacing-8 0;
  }
}

.title {
  color: $color-grey;
  margin-bottom: $spacing-40;
  width: 130px;
  text-align: center;

  @include lg {
    margin-bottom: $spacing-56;
  }
}

.addTest {
  margin-bottom: $spacing-8;

  @include lg {
    margin-bottom: 0;
  }
}
