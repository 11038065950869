@import 'src/scss/base';

.wrapper {
  background: $color-white;
  position: relative;
  padding: $spacing-24 $spacing-16;
  border: 1px solid $color-grey;
  border-radius: $spacing-16;
  color: $color-blacky;
}

.reorderWrapper {
  padding-bottom: $spacing-16;
}

.cardWrapper {
  display: flex;
  gap: $spacing-8;
  width: 100%;
  min-height: 92px;

  &.cardWrapperWithReorder {
    margin-bottom: $spacing-16;
  }

  @include lg {
    gap: $spacing-16;
  }
}

.imageWrapper {
  padding-top: $spacing-8;
  min-width: 105px;
  width: 105px;
  position: relative;

  @include lg {
    width: 150px;
  }
}

.image {
  width: 100%;
}

.rightSide {
  gap: $spacing-16;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: space-between;

  @include md {
    flex-direction: row;
  }
}

.textContentWrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.titleWrapper {
  display: flex;
  flex-direction: column;
  gap: $spacing-4;
  margin-bottom: $spacing-16;
}

.title {
  @include lg {
    @include subtitle-1;
  }
}

.description {
  @include lg {
    @include subtitle-2;
  }
}

.button {
  width: fit-content;
  align-self: flex-start;
}

.line {
  position: absolute;
  left: 0;
  width: 100%;
  height: 1px;
  background: $color-grey;
}

.reorderButtonWrapper {
  @include button-default;
  padding-top: $spacing-16;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.reorderButtonTextWrapper {
  display: flex;
  gap: $spacing-4;
}

.reorderButtonText {
  color: $color-grey-darker;
}

.prescriptionInfoLabel {
  display: inline-block;
  margin-right: $spacing-4;
  font-size: 11px;

  @include md {
    font-size: 12px;
  }
}

.prescriptionInfoData {
  display: inline-block;
}
