@import 'src/scss/base';

.phone-registration {
  position: relative;
  z-index: 1;
  padding: px2rem(25) px2rem(15);

  @include lg {
    margin-top: px2rem(75);
  }
}

.phone-registration__background {
  @include lg {
    .background__elipse-wrapper {
      height: 100%;
    }
  }
}