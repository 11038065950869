@import 'src/scss/base';

.wrapper {
  margin-top: $spacing-24;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.button {
  display: block;
  width: 100%;
  text-align: start;
  color: $color-white;
  @include button-default;
  padding: $spacing-24;
  border-radius: $spacing-8;

  @include md {
    padding: $spacing-32;
  }
}

.titleWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.title {
  width: 80%;
}

.description {
  margin-top: $spacing-8;
}
