@import 'src/scss/base';

.slider .swiper-slide {
  @media only screen and (min-width: 0px) and (max-width: 374px) {
    width: 200px !important;
  }

  @media only screen and (min-width: 375px) and (max-width: 449px) {
    width: 250px !important;
  }

  @media only screen and (min-width: 450px) and (max-width: 599px) {
    width: 320px !important;
  }

  @include md {
    width: unset;
  }
}

.slider__wrapper {
  @include md {
    transform: translate3d(100px, 0px, 0px) !important;
  }

  @include lg {
    transform: translate3d(220px, 0px, 0px) !important;
  }
}

.slider {
  padding-bottom: 30px !important;
  position: relative !important;
}

.slider .swiper-pagination {
  bottom: 0px !important;

  span {
    background: $color-purple !important;
  }
}
