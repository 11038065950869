@import 'src/scss/base';

.scorecard {
  width: px2rem(280);
  margin: px2rem(15) auto 0;
  padding: px2rem(15) px2rem(15) px2rem(25);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  margin-bottom: px2rem(60);

  @include lg {
    width: 100%;
    max-width: px2rem(920);
    display: flex;
    padding: 0;
    margin-bottom: px2rem(25);
  }
}

.scorecard__left-side {
  display: flex;
  flex-wrap: wrap;

  @include lg {
    flex-basis: 73%;
    padding: px2rem(25) px2rem(40) px2rem(45);
  }
}

.scorecard__illnesses {
  display: flex;
  flex-direction: column;
  width: px2rem(100);

  @include lg {
    width: px2rem(155);
  }
}

.scorecard__illness {
  @include font-aribau-bold;
  display: flex;
  align-items: center;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);
  min-height: px2rem(40);
  color: $color-black;

  @include lg {
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
    min-height: px2rem(55);
  }
}

.scorecard__score-bars {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;
}

.scorecard__score-bar-wrapper {
  display: flex;
  align-items: center;
  min-height: px2rem(40);

  @include lg {
    min-height: px2rem(55);
  }
}

.scorecard__score-bar-labels {
  @include font-aribau;
  color: $color-dark-grey;
  font-size: px2rem(10);
  line-height: px2lineheight(10, 13);
  justify-content: space-between;
  width: 100%;
  display: flex;
  padding-left: px2rem(100);

  @include lg {
    padding-left: px2rem(155);
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}

.scorecard__icon {
  width: px2rem(23);
  height: px2rem(23);
  border-radius: 50%;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  @include lg {
    width: px2rem(34);
    height: px2rem(34);
    align-self: start;
  }
}

.scorecard__icon-explain {
  display: flex;
  flex-basis: 50%;
  align-items: center;

  @include lg {
    flex-basis: unset;
    align-items: flex-start;

    &:first-child {
      margin-bottom: px2rem(30);
    }
  }
}

.scorecard__text {
  @include font-aribau-bold;
  color: $color-dark-grey;
  font-size: px2rem(10);
  line-height: px2lineheight(10, 13);
  padding-left: px2rem(6);
  display: flex;
  flex-direction: column;

  @include lg {
    padding-left: px2rem(8);
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}

.scorecard__right-side {
  display: flex;
  border-top: px2rem(1) solid $color-light-grey-3;
  margin-top: px2rem(4);
  padding-top: px2rem(13);
  flex-wrap: wrap;

  @include lg {
    flex-basis: 27%;
    border-top: none;
    border-left: px2rem(1) solid $color-light-grey-3;
    margin: 0;
    flex-wrap: unset;
    flex-direction: column;
    padding: px2rem(50) px2rem(30) 0 px2rem(35);
  }
}

.scorecard__read-more {
  @include font-aribau;
  border: none;
  background: none;
  padding: 0;
  color: $color-purple;
  text-align: left;

  .no-touchevents &:hover {
    cursor: pointer;
    color: $color-orange;
  }

  @include lg {
    text-decoration: underline;
  }
}

.scorecard__download-button {
  padding: 0 px2rem(10);
  font-size: px2rem(10);
  line-height: px2lineheight(10, 11);
  height: px2rem(30);
  margin-top: px2rem(35);
  // Remove in the future
  display: none;

  svg {
    fill: $color-purple;
    width: px2rem(15);
    margin-right: px2rem(3);
  }

  @include lg {
    margin-top: px2rem(40);
    align-self: flex-start;
  }
}

.scorecard__illness-name {
  display: block;
  position: relative;
}

.scorecard__illness-info {
  width: px2rem(15);
  height: px2rem(15);
  border-radius: 50%;
  text-decoration: none;
  position: absolute;
  right: px2rem(-15);
  top: px2rem(-5);
  display: flex;
  justify-content: center;
  align-items: center;

  @include lg {
    width: px2rem(18);
    height: px2rem(18);
    right: px2rem(-18);
  }
}

.scorecard__illness-info-icon {
  font-size: px2rem(12) !important;
  fill: $color-disabled !important;

  @include lg {
    font-size: px2rem(15) !important;
  }
}

.scorecard__source {
  @include font-aribau;
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  margin-top: px2rem(10);
}
