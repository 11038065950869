@import 'src/scss/base';

.carousel {
  position: relative;
  overflow: hidden;
}

.carouselSlide {
  padding-left: 24px;

  &.onlyOneCard {
    padding: 0 $spacing-24;
  }

  @include md {
    padding-left: 40px !important;
  }

  @include lg {
    padding: 0 !important;
  }

  &:last-child {
    margin-right: $spacing-24;

    @include md {
      margin-right: $spacing-40;
    }

    @include lg {
      margin-right: 0;
    }
  }
}

.arrowLeft {
  display: none;
  position: absolute;
  transform: translateY(-50%);
  z-index: 2;

  &:hover {
    background: $color-grey-light;
    border-radius: 50%;
  }

  @include lg {
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: -74px;
  }

  @media screen and (min-width: 1440px) {
    left: -90px;
  }
}

.arrowRight {
  display: none;
  position: absolute;
  transform: translateY(-50%);
  z-index: 2;

  &:hover {
    background: $color-grey-light;
    border-radius: 50%;
  }

  @include lg {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    right: -74px;
  }

  @media screen and (min-width: 1440px) {
    right: -90px;
  }
}
