@import 'src/scss/base';

.allergy-diagnosis {
  color: $color-blacky;
}

.allergy-diagnosis__allergens {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    gap: $spacing-24;
    margin-top: $spacing-40;
  }
}

.allergy-diagnosis__text {
  margin-top: $spacing-16;

  @include md {
    width: 550px;
    margin: 0 auto;
    margin-top: $spacing-40;
  }
}

.allergy-diagnosis__documents-wrapper-mobile {
  @include lg {
    display: none;
  }
}
