@import 'src/scss/base';

.actions-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  width: 100%;
  bottom: 0;
  left: 0;
  z-index: 10;
  background-color: $color-purple;
  background-color: rgba(85, 68, 148, 0);

  background: rgba(85, 68, 148, 0);
  background: -moz-linear-gradient(top, rgba(85, 68, 148, 0) 0%, rgba(85, 68, 148, 1) 35%, rgba(85, 68, 148, 1) 100%);
  background: -webkit-gradient(
    left top left bottom,
    color-stop(0%, rgba(85, 68, 148, 0)),
    color-stop(35%, rgba(85, 68, 148, 1)),
    color-stop(100%, rgba(85, 68, 148, 1))
  );
  background: -webkit-linear-gradient(
    top,
    rgba(85, 68, 148, 0) 0%,
    rgba(85, 68, 148, 1) 35%,
    rgba(85, 68, 148, 1) 100%
  );
  background: -o-linear-gradient(top, rgba(85, 68, 148, 0) 0%, rgba(85, 68, 148, 1) 35%, rgba(85, 68, 148, 1) 100%);
  background: -ms-linear-gradient(top, rgba(85, 68, 148, 0) 0%, rgba(85, 68, 148, 1) 35%, rgba(85, 68, 148, 1) 100%);
  background: linear-gradient(to bottom, rgba(85, 68, 148, 0) 0%, rgba(85, 68, 148, 1) 35%, rgba(85, 68, 148, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#554494', endColorstr='#554494', GradientType=0 );
}

.actions-bar__content {
  display: flex;
  justify-content: space-between;
  padding: px2rem(40) px2rem(12) px2rem(30);
  width: 100%;

  @include md {
    max-width: px2rem(925);
    padding: px2rem(40) px2rem(15) px2rem(30);
  }

  @include lg {
    padding: px2rem(45) px2rem(0) px2rem(30);
  }
}

.actions-bar__button {
  svg {
    margin: 0;
  }

  @include md {
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
  }
}

.actions-bar__button--next,
.actions-bar__button--skip {
  @include xs-only {
    min-width: px2rem(100);
  }

  .button__content {
    margin-right: px2rem(-25);
  }
}

.actions-bar__button--skip {
  margin-bottom: px2rem(10);
  padding: 0 px2rem(25) 0 px2rem(20);

  @include sm {
    margin-bottom: px2rem(0);
    margin-right: px2rem(10);
  }

  @include md {
    padding: 0 px2rem(30);
    margin-right: px2rem(15);
  }
}

.actions-bar__button--back {
  @include sm-only {
    min-width: px2rem(65);
    padding: 0;
    align-self: flex-end;
  }

  @include md {
    .button__content {
      margin-left: px2rem(-15);
    }
  }
}

.actions-bar__button--configure {
  display: none;
  padding: 0 px2rem(15);
  min-width: px2rem(140);
  margin-bottom: px2rem(10);

  svg {
    display: none;
  }

  @include md {
    padding: 0 px2rem(25) 0 px2rem(20);
    margin-right: px2rem(35);

    svg {
      display: block;
      margin-right: px2rem(7);
    }
  }
}

.actions-bar__button--basket {
  padding: 0 px2rem(15);
  min-width: px2rem(140);
  margin-left: px2rem(15);

  svg {
    display: none;
  }

  @include md {
    padding-left: px2rem(35);

    svg {
      display: block;
      margin-left: px2rem(13);
    }
  }
}

.actions-bar__button-text {
  display: block;
}

.actions-bar__button-text-wrapper {
  text-align: left;
  display: flex;
  flex-direction: column;

  .actions-bar__button-text {
    line-height: px2lineheight(16, 16);
  }
}

.actions-bar__button-subtext {
  font-size: px2rem(12);
  line-height: px2lineheight(12, 12);
  font-weight: normal;
}

.actions-bar__right-button-wrapper {
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin-left: px2rem(10);
}

.actions-bar__right-button-wrapper--only {
  margin-left: auto;
}

.actions-bar__right-button-wrapper--with-skip {
  @include xs-only {
    flex-direction: column;
    align-items: flex-end;
  }
}

.actions-bar__recommendation-back-button {
  @include sm-only {
    min-width: px2rem(70);

    .button__content {
      margin: 0;
    }
  }
}

.actions-bar__button--loading {
  padding: 0 px2rem(20);

  .button__content {
    margin-right: 0;
    margin-left: 0;
  }
}
