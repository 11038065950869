@import 'src/scss/base';

.wrapper {
  width: 100%;
  position: relative;
}

.label {
  @include body-2;
  color: $color-grey-darker;
}

.input {
  @include body-1;
  padding: $spacing-24;
  border-radius: $spacing-8;
  border: 1px solid $color-grey;
  color: $color-grey-dark;
  outline: none;
  width: 100%;

  &:focus,
  &:active {
    border: 1px solid $color-secondary;
    box-shadow: $input-box-shadow;
    color: $color-black;
  }

  &:not(:placeholder-shown):not(:active):not(:focus):not(:disabled) {
    border: 1px solid $color-black;
    color: $color-black;
  }

  &:disabled {
    border: 1px solid $color-grey;
    color: $color-grey-dark;
    opacity: 0.38;
  }

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.error_input:not(:placeholder-shown):not(:active):not(:focus):not(:disabled) {
  border: 1px solid $color-warning;
}

.error_text {
  @include caption;
  position: absolute;
  bottom: -36px;
  color: $color-warning;
  width: 100%;
  height: $spacing-32;
  word-wrap: break-word;

  &:disabled {
    color: $color-black;
    opacity: 0.38;
  }
}
