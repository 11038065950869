@import 'src/scss/base';

.price {
  @include font-aribau-bold;
  color: $color-white;
  width: px2rem(52);
  height: px2rem(52);
  background: $color-orange;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;

  @include md {
    width: px2rem(60);
    height: px2rem(60);
  }
}

.price--with-discount {
  padding-bottom: px2rem(10);
}

.price__discount-box {
  width: px2rem(55);
  height: px2rem(22);
  border-radius: px2rem(4);
  background-color: $color-purple;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  bottom: px2rem(-8);
  font-size: 7px;
  line-height: 9px;

  @include md {
    width: px2rem(68);
    height: px2rem(25);
  }
}

.price__currency {
  font-size: px2rem(10);
  line-height: px2lineheight(10, 10);

  @include md {
    font-size: px2rem(12);
    line-height: px2lineheight(12, 12);
  }
}

.price__value {
  font-size: px2rem(22);
  line-height: px2lineheight(22, 22);

  @include md {
    font-size: px2rem(26);
    line-height: px2lineheight(26, 26);
  }
}

.price__value--long {
  font-size: px2rem(18);
  line-height: px2lineheight(18, 18);

  @include md {
    font-size: px2rem(20);
    line-height: px2lineheight(20, 20);
  }
}

.price__discount-text {
  text-transform: uppercase;
}
