@import 'src/scss/base';

.wrapper {
  position: relative;
}

.button {
  @include caption;
  display: flex;
  align-items: center;
  gap: $spacing-8;
  padding: $spacing-8 $spacing-16;
  height: unset;
  min-height: unset;

  @include md {
    font-size: 14px;
  }

  @include lg {
    cursor: pointer;
  }
}

.tooltip {
  @include caption;
  position: absolute;
  top: $spacing-8;
  right: -$spacing-64;
  display: block;
  padding: $spacing-4 $spacing-8;
  background: $color-grey-dark;
  color: $color-white;
  border-radius: $spacing-8;
  z-index: 1;
}

.iconWrapper {
  width: 20px;
  height: 20px;

  svg {
    width: 100%;
    height: 100%;
    margin: 0;
  }
}
