@import 'src/scss/base';

.doctors-report {
  @include font-aribau;
  background-color: $color-dirty-white;
  color: $color-dark-grey;
  padding: px2rem(20);
  padding-top: px2rem(15);
  border-radius: 10px;
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  margin-top: px2rem(30);
  position: relative;

  @include md {
    padding-top: px2rem(20);
  }
}

.doctors-report__text {
  margin-bottom: px2rem(8);
}

.doctors-report__bottom-text {
  margin-top: px2rem(15);
}

.doctors-report__read-more,
.doctors-report__read-less {
  text-decoration: underline;
}

.doctor-report__icon {
  width: px2rem(35);
  height: px2rem(35);
  position: absolute;
  top: px2rem(-12);
  left: px2rem(-12);
}

.doctors-report__title {
  @include font-aribau;
  color: $color-purple;
  font-size: px2rem(16);
  font-weight: bold;
  margin-bottom: px2rem(5);
}
