@import 'src/scss/base';

.progress-bar {
  @include font-aribau;
  background-color: $color-white;
  margin-bottom: px2rem(14);

  .progress-bar__arrow-icon {
    font-size: px2rem(13);
    margin: 0 px2rem(2);

    @include sm {
      font-size: px2rem(20);
      font-size: px2rem(14);
      margin: 0 px2rem(5);
    }

    @include md {
      margin: 0 px2rem(10);
    }
  }

  @include md {
    padding-top: px2rem(10);
  }

  @include lg {
    padding-top: px2rem(5);
  }
}

.progress-bar--with-sticky-title {
  @include md {
    margin-bottom: px2rem(80);
  }
}

.progress-bar__content {
  color: $color-grey;
  max-width: px2rem(460);
  margin: 0 auto;

  @include lg {
    max-width: px2rem(520);
  }
}

.progress-bar__title {
  color: $color-purple;
  letter-spacing: 0.18px;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
  text-align: center;
  display: block;

  @include sm {
    font-size: px2rem(22);
    line-height: px2lineheight(22, 29);
  }

  @include lg {
    letter-spacing: 0.27px;
    font-size: px2rem(28);
    line-height: px2lineheight(28, 36);
  }
}

.progress-bar__bar-wrapper {
  width: 100%;
  height: px2rem(3);
  background-color: $color-light-grey;
  position: relative;

  @include lg {
    height: px2rem(4);
  }
}

.progress-bar__bar {
  background-color: $color-purple;
  position: absolute;
  height: px2rem(3);
  top: 0;
  left: 0;

  @include lg {
    height: px2rem(4);
  }
}

.progress-bar__steps {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  margin: px2rem(8) 0;

  @include lg {
    margin: px2rem(2) 0 px2rem(9);
  }
}
