@import 'src/scss/base';

.background {
  position: relative;
  min-height: 100vh;
  overflow: auto;
  background-color: $color-purple;
  overflow-x: hidden;
}

.background__elipse-wrapper {
  position: absolute;
  left: 50%;
}

.background__elipse {
  width: px2rem(1200);
  height: px2rem(500);
  border-radius: 50%;
  background-color: $color-white;
  position: relative;
  z-index: 1;
  left: -50%;
  top: px2rem(-250);

  @include xs-only {
    width: px2rem(1000);
  }

  @include md {
    width: px2rem(1600);
    height: px2rem(600);
    top: px2rem(-300);
  }

  @include lg {
    width: px2rem(2400);
    height: px2rem(1000);
    top: px2rem(-620);
  }

  @include xl {
    width: px2rem(3400);
    height: px2rem(1500);
    top: px2rem(-1120);
  }
}

.background__background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}
