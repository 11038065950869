@import 'src/scss/base';

.content {
  padding: $spacing-16 $spacing-24;
  // TODO: Check why has to be important?
  background-repeat: no-repeat !important;
  background-size: cover !important;
  border-radius: $spacing-16;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: start;
}

.eyebrowWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.icon {
  margin-bottom: $spacing-8;
  height: 30px;
}

.arrow {
  align-self: flex-start;
}
