@import 'src/scss/base';

.wrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.input {
  width: 100%;
}

.icon {
  position: absolute;
  display: flex;
  align-items: center;
  bottom: $spacing-24;

  &.disabled {
    opacity: 0.38;
  }
}

.left_icon {
  left: 0;
  padding-left: 20px;
}

.right_icon {
  right: 0;
  padding-right: 12px;
}

.padding_right input {
  padding-right: 52px;
}

.padding_left input {
  padding-left: 52px;
}
