@import 'src/scss/base';

.date-picker {
  padding-top: 20px;
  min-height: 320px;
  max-width: px2rem(925);
  margin: 0 auto px2rem(50);

  @include lg {
    height: px2rem(320);
  }
}

.date-picker__year-month--month-open {
  margin-bottom: px2rem(220);
}

.date-picker__year-month-day {
  margin-bottom: px2rem(60);
}

.date-picker__year-month-day--month-open {
  margin-bottom: px2rem(100);
}

.date-picker__year-month-day--day-open {
  margin-bottom: px2rem(280);
}

.date-picker__year-month-day-time {
  margin-bottom: px2rem(60);
}

.date-picker__year-month-day-time--day-open {
  margin-bottom: px2rem(200);

  @include lg {
    margin-bottom: px2rem(100);
  }
}

.date-picker__year-month-day-time--time-open {
  margin-bottom: px2rem(280);

  @include lg {
    margin-bottom: px2rem(100);
  }
}

.date-picker__content {
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  padding: 45px 35px;

  @include lg {
    padding: 60px 50px;
  }
}

.date-picker__inner-content {
  @include lg {
    display: flex;
    justify-content: center;
  }
}

.date-picker__error {
  @include font-aribau-bold;
  display: block;
  text-align: center;
  color: $color-red;
  margin-top: px2rem(20);
}
