@import 'src/scss/base';

.question-dropdown {
  padding: 20px 0 40px;
  max-width: px2rem(925);
  margin: 0 auto px2rem(50);

  @include lg {
    padding-bottom: 60px;
  }
}

.question-dropdown__content {
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  padding: 45px;
  text-align: center;
  min-height: px2rem(180);
  display: flex;
  align-items: center;
  justify-content: center;

  @include lg {
    padding: 60px 50px;
    min-height: px2rem(200);
  }
}

.question-dropdown__element {
  @include font-aribau-bold;
  margin: 0 auto;
  width: 100%;

  @include lg {
    width: 60%;
  }
}

.question-dropdown__control {
  padding: px2rem(10);
}

.question-dropdown__arrow {
  top: px2rem(18);
}

.question-dropdown__placeholder {
  color: $color-grey;
}

.question-dropdown__placeholder--selected {
  color: $color-black;
}

.question-dropdown__menu {
  text-align: left;
}
