@import 'src/scss/base';

.h1 {
  font-family: 'Aribau Grotesk';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -1.5px;

  @include md {
    font-size: 64px;
    line-height: 86px;
  }

  @include lg {
    font-size: 74px;
  }
}

.h2 {
  font-family: 'Aribau Grotesk';
  font-size: 36px;
  font-style: normal;
  font-weight: 500;
  line-height: 43.2px;
  letter-spacing: -0.5px;

  @include md {
    font-size: 48px;
    line-height: 64px;
  }

  @include lg {
    font-size: 64px;
    line-height: 76px;
  }
}

.h3 {
  font-family: 'Aribau Grotesk';
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;

  @include md {
    font-size: 36px;
    line-height: 48px;
  }

  @include lg {
    font-size: 48px;
    line-height: 57px;
  }
}

.h4 {
  font-family: 'Aribau Grotesk';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 28.8px;

  @include md {
    font-size: 28px;
    line-height: 33.6px;
  }

  @include lg {
    font-size: 40px;
    line-height: 48px;
  }
}

.h5 {
  font-family: 'Aribau Grotesk';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 21px;

  @include md {
    font-size: 20px;
    line-height: 28px;
  }

  @include lg {
    font-size: 24px;
    line-height: 28.8px;
  }
}

.h6 {
  font-family: 'Aribau Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.15px;

  @include md {
    font-size: 16px;
    line-height: 20px;
  }

  @include lg {
    font-size: 18px;
    line-height: 21px;
  }
}

//Shared styles
.subtitle1 {
  font-family: 'Aribau Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
}

.subtitle2 {
  font-family: 'Aribau Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 19.6px;
}

.body1 {
  font-family: 'Aribau Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.5px;
}

.body2 {
  font-family: 'Aribau Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 19.6px;
  letter-spacing: 0.25px;
}

.caption {
  color: inherit;
  font-family: 'Aribau Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.button {
  font-family: 'Aribau Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  letter-spacing: 0.25px;
}

.overline {
  font-family: 'Aribau Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}
