@import 'src/scss/base';

.wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.title {
  display: block;
  margin-top: $spacing-24;
  margin-bottom: $spacing-16;

  @include md {
    margin-top: $spacing-40;
    margin-bottom: $spacing-16;
  }
}

.date {
  color: $color-grey-darker;
  margin-bottom: $spacing-16;
}
