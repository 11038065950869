@mixin hide-scrollbar {
  // Hidding scrollbars Firefox
  scrollbar-width: none;

  // Hidding scrollbars IE 10+
  -ms-overflow-style: none;

  // Hidding scrollbars on WebKit - Safari, Chrome, Opera
  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
}

@mixin input-reset {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}

@mixin card-shadow {
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
}

@mixin list-reset {
  padding: 0;
  margin: 0;
  list-style: none;
}

@mixin button-default {
  outline: none;
  text-decoration: none;
  padding: 0;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

@mixin focus-outline($color: $color-black, $background-color: $color-white) {
  box-shadow: 0 0 0 2px $background-color, 0 0 0 3px $color;
}

@mixin focus-visible-outline(
  $color: $color-black,
  $background-color: $color-white
) {
  outline: none;

  &.focus-visible {
    @include focus-outline($color, $background-color);
  }
}

@mixin font-aribau {
  font-family: 'Aribau Grotesk';
  font-weight: 300;
}

@mixin font-aribau-bold {
  font-family: 'Aribau Grotesk';
  font-weight: 700;
}

@mixin font-arial {
  font-family: 'Arial';
  font-weight: 300;
}

@mixin font-arial-bold {
  font-family: 'Arial';
  font-weight: 700;
}

@mixin subtitle-1 {
  font-family: 'Aribau Grotesk';
  font-weight: 400;
  font-size: 16px;
  line-height: 21px;
}

@mixin subtitle-2 {
  font-family: 'Aribau Grotesk';
  font-weight: 500;
  font-size: 14px;
  line-height: 18.2px;
}

@mixin body-1 {
  font-family: 'Aribau Grotesk';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: 0.5px;
}

@mixin body-2 {
  font-family: 'Aribau Grotesk';
  font-size: 14px;
  font-style: normal;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.25px;
}

@mixin button-text {
  font-family: 'Aribau Grotesk';
  font-weight: 500;
  font-size: 16px;
  line-height: 16px;
  letter-spacing: 0.25px;
}

@mixin small-button-text {
  font-family: 'Aribau Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@mixin caption {
  font-family: 'Aribau Grotesk';
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

@mixin overline {
  font-family: 'Aribau Grotesk';
  font-size: 12px;
  line-height: 16px;
}
