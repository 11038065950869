@import 'src/scss/base';

$button-box-shadow: 0px 1px 3px 1px rgba(0, 0, 0, 0.15),
  0px 1px 2px 0px rgba(0, 0, 0, 0.3);

@mixin elevated-background {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.normal {
  @include button-text;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  // spacing when link has icon
  gap: 8px;
  position: relative;
  min-width: 145px;
  width: fit-content;
  padding: 10px 24px;
  border-radius: 100px;
  outline: none;
  min-height: 48px;
  text-align: center;

  :global(.no-touchevents) & {
    cursor: pointer;
  }

  & svg {
    margin-left: -8px;
  }
}

.small {
  @include small-button-text;
  padding: 8px 16px;
  min-height: 32px;
  min-width: 130px;
}

.primary {
  background: $color-gradient;
  color: $color-white;
  border: none;

  :global(.no-touchevents) &:hover {
    opacity: 0.92;
    box-shadow: $button-box-shadow;
  }

  &:focus {
    opacity: 0.92;
  }

  &:active {
    opacity: 0.92;
    box-shadow: none;
  }

  &:disabled {
    background: $color-grey-disabled;
    color: rgba($color-blacky, 0.38);
    cursor: not-allowed;
    box-shadow: none;
    opacity: 1;

    svg,
    path {
      fill: rgba($color-blacky, 0.38);
    }
  }
}

.outlined {
  background: $color-white;
  border: 1px solid $color-secondary;
  color: $color-secondary;

  :global(.no-touchevents) &:hover {
    background: rgba($color-secondary, 0.08);
  }

  &:focus {
    background: rgba($color-secondary, 0.12);
    outline: none;
  }

  &:active {
    background: rgba($color-secondary, 0.12);
  }

  &:disabled {
    background: $color-white;
    border: 1px solid rgba($color-grey-disabled, 0.12);
    color: rgba($color-blacky, 0.38);
    cursor: not-allowed;

    svg,
    path {
      fill: rgba($color-blacky, 0.38);
    }
  }
}

.text {
  background: $color-white;
  border: none;
  color: $color-secondary;

  :global(.no-touchevents) &:hover {
    background: rgba($color-secondary, 0.08);
  }

  &:focus {
    background: rgba($color-secondary, 0.12);
  }

  &:active {
    background: rgba($color-secondary, 0.12);
  }

  &:disabled {
    background: $color-white;
    color: rgba($color-blacky, 0.38);
    cursor: not-allowed;

    svg,
    path {
      fill: rgba($color-blacky, 0.38);
    }
  }
}

.elevated {
  position: relative;
  background: $color-gradient;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  border: none;
  box-shadow: $button-box-shadow;

  :global(.no-touchevents) &:hover::after {
    @include elevated-background;
    border-radius: 100px;
    background: rgba($color-secondary, 0.08);
    box-shadow: $button-box-shadow;
  }

  &:focus::after {
    @include elevated-background;
    background: rgba($color-secondary-medium, 0.12);
    box-shadow: $button-box-shadow;
    border-radius: 100px;
  }

  &:active::after {
    @include elevated-background;
    background: rgba($color-secondary-medium, 0.12);
    box-shadow: $button-box-shadow;
    border-radius: 100px;
  }

  &:disabled {
    background: rgba($color-blacky, 0.12);
    cursor: not-allowed;
    -webkit-text-fill-color: rgba($color-blacky, 0.38);
    box-shadow: none;

    svg,
    path {
      fill: rgba($color-blacky, 0.38);
    }
  }

  &:disabled:hover::after {
    content: none;
  }
}

.tonal {
  background: $color-white;
  color: rgba($color-secondary, 1);
  border: none;

  :global(.no-touchevents) &:hover {
    background: rgba($color-grey-medium, 0.08);
    box-shadow: $button-box-shadow;
  }

  &:focus {
    background: rgba($color-grey-medium, 0.12);
  }

  &:active {
    background: rgba($color-grey-medium, 0.12);
    box-shadow: none;
  }

  &:disabled {
    background: rgba($color-grey-disabled, 0.12);
    color: rgba($color-blacky, 0.38);
    cursor: not-allowed;
    box-shadow: none;

    svg,
    path {
      fill: rgba($color-blacky, 0.38);
    }
  }
}
