@import 'src/scss/base';

.results-block__lab-results {
  @include font-aribau-bold;

  font-size: px2rem(16);
  color: $color-purple;
  margin-top: px2rem(25);
  margin-bottom: px2rem(15);

  @include md {
    font-size: px2rem(18);
    margin-top: px2rem(25);
    margin-bottom: px2rem(20);
  }
}

.test-result__results-group-content--open {
  padding-top: 0;
  padding-bottom: px2rem(30);

  .test-result-info__content {
    width: 98%;

    @include lg {
      width: 100%;
    }
  }

  .test-result-info__circle {
    right: -3px;
  }

  .test-result-info {
    @include lg {
      width: 98%;
    }
  }
}

.test-result__results-group {
  .accordion__title {
    display: block;
    color: $color-black;
    font-size: px2rem(14);
  }

  .accordion__subtitle {
    font-size: px2rem(12);
  }

  .test-result-info__title {
    font-size: px2rem(13);
  }

  @include md {
    .accordion__title {
      display: inline-block;
      font-size: px2rem(16);
    }

    .accordion__subtitle {
      font-size: px2rem(14);
    }

    .test-result-info__title {
      font-size: px2rem(14);
    }
  }

  @include lg {
    width: 80%;
  }
}
