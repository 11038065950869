@import 'src/scss/base';

.input {
  margin-bottom: px2rem(20);
  position: relative;
}

.input__wrapper {
  display: flex;
  width: 100%;
  border: 1px solid #E0E0E0;

  &--focus {
    border-color: #848484;
  }
}

.input__icon-wrapper {
  width: px2rem(50);
  height: px2rem(50);
  background-color: #E5E5E5;
  display: flex;
  justify-content: center;
  align-items: center;
}

.input__input {
  @include font-aribau;
  font-size: px2rem(14);
  flex-grow: 1;
  border: none;
  padding: 0;
  padding-left: px2rem(15);
  
  &::placeholder {
    color: #848484;
  }
}

.input__name {
  @include font-aribau;
  font-size: px2rem(14);
  color: $color-black;
  margin-bottom: px2rem(7);
  display: block;
}

.input__error {
  @include font-aribau;
  font-size: px2rem(14);
  color: $color-red;
  display: block;
  margin-top: px2rem(5);

  &--absolute {
    position: absolute;
  }
}

.input__show-password-button {
  background: none;
  border: 0;
  width: px2rem(45);
  display: block;
  justify-content: center;
  align-items: center;
  padding: px2rem(5) px2rem(12) 0;
  

  .no-touchevents & {
    cursor: pointer;
  }
}