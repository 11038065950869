@import 'src/scss/base';

.wrapper {
  position: relative;
}

.error {
  @include caption;
  position: absolute;
  bottom: -36px;
  color: $color-warning;
  width: 100%;
  height: 32px;
  word-wrap: break-word;

  &:disabled {
    color: $color-black;
    opacity: 0.38;
  }
}
