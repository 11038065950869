@import 'src/scss/base';

.personal-hayfever {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.personal-hayfever__content {
  @include font-aribau;
  margin: 0 px2rem(10);
  padding: px2rem(28) px2rem(28) px2rem(35);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  max-width: px2rem(920);

  @include md {
    margin: 0 px2rem(30);
    padding: px2rem(35);
  }

  @include lg {
    display: flex;
    margin: 0 auto;
    padding: px2rem(30);
  }
}

.personal-hayfever__top-text {
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  color: $color-dark-grey;
  margin-bottom: px2rem(10);

  @include md {
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
  }

  @include lg {
    margin-bottom: px2rem(20);
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
  }
}

.personal-hayfever__image-wrapper {
  width: px2rem(200);
  height: px2rem(200);
  margin: 0 auto;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  @include lg {
    width: px2rem(260);
    height: px2rem(260);
  }
}

.personal-hayfever__list {
  margin: px2rem(25) 0;
  flex-shrink: 0;

  @include lg {
    margin: 0 0 px2rem(35);
  }
}

.personal-hayfever__list-item {
  border-top: 1px solid $color-border-grey;
  padding: px2rem(6) 0;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: 1px solid $color-border-grey;
  }
}

.personal-hayfever__list-item-image {
  width: px2rem(32);
  height: px2rem(32);
  border: 2px solid $color-purple;
  border-radius: 50%;
  overflow: hidden;

  img {
    width: 100%;
  }

  @include lg {
    border: 0;
  }
}

.personal-hayfever__list-item-name {
  @include font-aribau-bold;
  font-size: px2rem(14);
  color: $color-black;
  margin-left: px2rem(12);
}

.personal-hayfever__calendar {
  width: px2rem(14);
  height: px2rem(15);
  fill: $color-purple;
  margin-top: px2rem(-2);
}

.personal-hayfever__date-text {
  @include font-aribau;
  font-size: px2rem(10);
  color: $color-black;
  margin-left: px2rem(3);
}

.personal-hayfever__name-wrapper {
  display: flex;
  align-items: center;
  width: 60%;
}

.personal-hayfever__date-wrapper {
  display: flex;
  align-items: center;
  width: 40%;
}

.personal-hayfever__info-wrapper {
  display: flex;
  align-items: center;

  @include lg {
    margin-top: auto;
  }
}

.personal-hayfever__info-icon {
  flex-basis: px2rem(28);
  height: px2rem(28);
  flex-shrink: 0;

  svg {
    width: 100%;
    fill: $color-disabled;
  }
}

.personal-hayfever__info-text {
  @include font-aribau;
  font-size: px2rem(10);
  color: $color-black;
  text-align: left;
  margin-left: px2rem(15);

  a {
    @include font-aribau;
    font-size: px2rem(10);
    color: $color-purple;
  }
}

.personal-hayfever__text-content {
  @include lg {
    display: flex;
    flex-direction: column;
    padding-left: px2rem(50);
  }
}

.personal-hayfever__markdown {
  a {
    color: $color-purple;
  }

  h1,
  h2,
  h3 {
    margin-bottom: px2rem(10);
  }

  h4 {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
    margin-bottom: px2rem(12);
  }

  p {
    margin-bottom: px2rem(15);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-purple;
    }
  }

  ul {
    @include list-reset;
    margin-bottom: px2rem(20);
  }

  ol {
    margin: 0 0 px2rem(20) 0;
    padding-left: px2rem(20);
  }

  ol li {
    padding: px2rem(5) 0 0 0;
  }

  ul li {
    padding-left: px2rem(20);
    display: block;
    position: relative;

    &:before {
      content: '';
      border-radius: 50%;
      width: px2rem(8);
      height: px2rem(8);
      background: $color-purple;
      position: absolute;
      left: px2rem(5);
      top: px2rem(4);
    }
  }

  @include md {
    h4 {
      font-size: px2rem(16);
      line-height: px2lineheight(16, 21);
      margin-bottom: px2rem(15);
    }

    ul li {
      &:before {
        top: px2rem(6);
      }
    }
  }

  @include lg {
    p {
      margin-bottom: px2rem(20);
    }

    h4 {
      font-size: px2rem(18);
      line-height: px2lineheight(18, 23);
      margin-bottom: px2rem(10);
    }

    ul li {
      &:before {
        top: px2rem(8);
      }
    }
  }
}
