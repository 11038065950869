@import 'src/scss/base';

.cards {
  display: flex;
  flex-direction: column;
  gap: $spacing-16;
}

.prescriptions {
  margin-bottom: $spacing-16;
}
