@import 'src/scss/base';

.checkbox {
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox__label {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: left;

  &--disabled {
    opacity: 0.8;
  }

  @include lg {
    cursor: pointer;
  }
}

.checkbox__input {
  -webkit-appearance: none;
  appearance: none;
  background-color: $color-white;
  margin: 0;
  font: inherit;
  color: currentColor;
  width: px2rem(18);
  height: px2rem(18);
  border: 0.15em solid currentColor;
  border-radius: 0.15em;
  transform: translateY(-0.075em);
  margin-right: px2rem(5);
  line-height: normal;
  flex-shrink: 0;

  @include lg {
    width: px2rem(20);
    height: px2rem(20);
    margin-top: px2rem(3);
    margin-right: px2rem(8);
  }
}

.checkbox__input--orange {
  border-color: $color-dark-grey-2;
}

.checkbox__input--orange:disabled {
  opacity: 0.3;
}

.checkbox__input:checked {
  border-color: $color-purple;
  background-color: $color-purple;
}

.checkbox__input--orange:checked {
  border-color: $color-orange;
  background-color: $color-orange;
}

.checkbox__checkmark {
  left: px2rem(6);
  width: px2rem(5);
  height: px2rem(10);
  border: solid $color-white;
  border-width: 0 px2rem(2) px2rem(2) 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  position: absolute;
  top: calc(50% - 7px);

  @include lg {
    left: px2rem(7);
    width: px2rem(6);
    height: px2rem(12);
    border-width: 0 px2rem(3) px2rem(3) 0;
  }
}

.checkbox__checkmark--radio {
  top: px2rem(4.6);
}

.checkbox__input--radio {
  border-radius: 50%;
}