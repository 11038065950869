@import 'src/scss/base';

.health-check-recommendation {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.health-check-recommendation__content {
  @include font-aribau;
  display: flex;
  flex-direction: column;
  margin: 0 px2rem(10);
  padding: px2rem(30) px2rem(15) px2rem(35);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  color: $color-dark-grey;
  max-width: px2rem(920);
  text-align: center;

  a {
    color: #554494;
  }

  h1,
  h2,
  h3 {
    margin-bottom: px2rem(10);
  }

  h4 {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
    margin-bottom: px2rem(12);
  }

  p {
    margin-bottom: px2rem(15);

    &:last-child {
      margin-bottom: 0;
    }

    a {
      color: $color-purple;
    }
  }

  ul {
    @include list-reset;
    margin-bottom: px2rem(20);
  }

  ol {
    margin: 0 0 px2rem(20) 0;
    padding-left: px2rem(20);
  }

  ol li {
    padding: px2rem(5) 0 0 0;
  }

  ul li {
    padding-left: px2rem(20);
    display: block;
    position: relative;

    &:before {
      content: '';
      border-radius: 50%;
      width: px2rem(8);
      height: px2rem(8);
      background: $color-purple;
      position: absolute;
      left: px2rem(5);
      top: px2rem(4);
    }
  }

  @include md {
    margin: 0 px2rem(30);
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
    padding: px2rem(35);

    h4 {
      font-size: px2rem(16);
      line-height: px2lineheight(16, 21);
      margin-bottom: px2rem(15);
    }

    ul li {
      &:before {
        top: px2rem(6);
      }
    }
  }

  @include lg {
    display: flex;
    margin: 0 auto;
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
    padding: px2rem(35) px2rem(40) px2rem(45);

    p {
      margin-bottom: px2rem(20);
    }

    h4 {
      font-size: px2rem(18);
      line-height: px2lineheight(18, 23);
      margin-bottom: px2rem(10);
    }

    ul li {
      &:before {
        top: px2rem(8);
      }
    }
  }
}

.health-check-recommendation__subtitle {
  @include font-arial;
  color: $color-purple;
  margin-top: px2rem(15);
  margin-bottom: px2rem(5);
  display: block;
  font-size: px2rem(15);
  display: block;
  text-align: left;

  @include md {
    font-size: px2rem(18);
  }
}