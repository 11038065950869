@import 'src/scss/base';

.step {
  color: $color-grey;
  font-family: 'Aribau Grotesk';
  display: flex;
  align-items: center;

  svg {
    display: none;
  }

  .step__circle {
    background-color: $color-grey;
  }
}

.step--inProgress {
  color: $color-purple;

  .step__circle {
    background-color: $color-purple;
  }
}

.step--completed {
  color: $color-orange;

  svg {
    display: block;
    fill: $color-white;
    font-size: px2rem(8);
  }

  .step__circle {
    background-color: $color-orange;
  }

  @include md {
    svg {
      font-size: px2rem(12);
    }
  }

  @include lg {
    svg {
      font-size: px2rem(15);
    }
  }
}

.step__circle {
  width: px2rem(10);
  height: px2rem(10);
  border-radius: 50%;
  margin-right: px2rem(5);
  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    width: px2rem(14);
    height: px2rem(14);
    margin-right: px2rem(9);
  }

  @include lg {
    width: px2rem(17);
    height: px2rem(17);
  }
}

.step__title {
  display: flex;
  font-size: px2rem(10);
  line-height: px2lineheight(10, 13);
  align-items: center;

  @include md {
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
  }

  @include lg {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}
