@import 'src/scss/base';

.container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($color-black, 0.6);
  z-index: 1001;

  &.open {
    display: block;
  }
}

.wrapper {
  padding: $spacing-80 $spacing-24 $spacing-80 $spacing-24;
  position: fixed;
  bottom: 0;
  top: 0;
  right: 0;
  overflow-y: auto;
  background: $color-white;
  transition: transform 0.2s ease-in-out;
  transform: translateX(100%);

  @include md {
    width: 500px;
  }

  &.transform {
    transform: translateX(0);
  }

  @include lg {
    padding: $spacing-80 $spacing-48 $spacing-80 $spacing-48;
  }
}

.icon {
  cursor: pointer;
  position: fixed; /* Change to fixed */
  top: 32px;
  right: 32px;
  z-index: 1002;
  opacity: 0;
  transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out;
  transform: translateX(100%);

  &.transform {
    opacity: 1;
    transform: translateX(0);
    background-color: $color-white;
  }
}

.drawerContent {
  font-family: 'Aribau Grotesk';
  color: $color-blacky;
  position: relative;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    &:not(:first-child) {
      margin-top: 48px;
    }
  }

  h1 {
    font-family: 'Aribau Grotesk';
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -1.5px;

    @include md {
      font-size: 64px;
      line-height: 86px;
    }

    @include lg {
      font-size: 74px;
    }
  }

  h2 {
    font-family: 'Aribau Grotesk';
    font-size: 36px;
    font-style: normal;
    font-weight: 500;
    line-height: 43.2px;
    letter-spacing: -0.5px;

    @include md {
      font-size: 48px;
      line-height: 64px;
    }

    @include lg {
      font-size: 64px;
      line-height: 76px;
    }
  }

  h3 {
    font-family: 'Aribau Grotesk';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;

    @include md {
      font-size: 36px;
      line-height: 48px;
    }

    @include lg {
      font-size: 48px;
      line-height: 57px;
    }
  }

  h4 {
    font-family: 'Aribau Grotesk';
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 28.8px;

    @include md {
      font-size: 28px;
      line-height: 33.6px;
    }

    @include lg {
      font-size: 40px;
      line-height: 48px;
    }
  }

  h5 {
    font-family: 'Aribau Grotesk';
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;

    @include md {
      font-size: 20px;
      line-height: 28px;
    }

    @include lg {
      font-size: 24px;
      line-height: 28.8px;
    }
  }

  h6 {
    font-family: 'Aribau Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.15px;

    @include md {
      font-size: 16px;
      line-height: 20px;
    }

    @include lg {
      font-size: 18px;
      line-height: 21px;
    }
  }

  ul {
    color: $color-blacky;
    padding: 0;
    padding-left: 10px;
    list-style: none;
  }

  ul li {
    position: relative;
    padding-left: 6px;
    font-family: 'Aribau Grotesk';
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    letter-spacing: 0.5px;
    margin-bottom: 16px;
  }

  ul li::before {
    content: '';
    position: absolute;
    top: 10px;
    left: -10px;
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: $color-gradient;
  }

  ol {
    counter-reset: item; /* Reset the counter */
    list-style: none;
    padding-left: 0;
  }

  ol li {
    counter-increment: item; /* Increment the counter */
    margin-bottom: 10px;
    position: relative;
    padding-left: $spacing-32;
  }

  ol li::before {
    content: '0' counter(item); /* Format numbers as 01, 02, etc. */
    position: absolute;
    left: -2px;
    top: 0;
    width: $spacing-24;
    text-align: center;
    background: $color-gradient;
    -webkit-background-clip: text;
    color: transparent;
  }

  a {
    @include button-default;
    background: $color-gradient;
    -webkit-background-clip: text;
    color: transparent;
    margin-bottom: $spacing-24;
    display: block;
  }

  button {
    @include button-text;
    @include small-button-text;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    // spacing when button has icon
    gap: 8px;
    position: relative;
    padding: 8px 16px;
    min-height: 32px;
    min-width: 130px;
    border-radius: 100px;
    outline: none;

    :global(.no-touchevents) & {
      cursor: pointer;
    }

    & svg {
      margin-left: -8px;
    }

    background: $color-gradient;
    color: $color-white;
    border: none;

    :global(.no-touchevents) &:hover {
      opacity: 0.92;
      box-shadow: $button-box-shadow;
    }

    &:focus {
      opacity: 0.92;
    }

    &:active {
      opacity: 0.92;
      box-shadow: none;
    }

    &:disabled {
      background: $color-grey-disabled;
      color: rgba($color-blacky, 0.38);
      cursor: not-allowed;
      box-shadow: none;
      opacity: 1;

      svg,
      path {
        fill: rgba($color-blacky, 0.38);
      }
    }
  }
}
