@import 'src/scss/base';

.employee-health-program-footer-cta {
  @include button-default;
  width: 100%;
  position: relative;
  border-top: 1px solid $color-grey;
  color: $color-grey-darker;
  display: flex;
  gap: $spacing-8;
  align-items: center;
  padding: $spacing-16 $spacing-24;
}

.employee-health-program-footer-cta__text-wrapper {
  position: relative;
}

.employee-health-program-footer-cta__info-icon {
  position: absolute;
  right: -30px;
  top: -4px;
}
