@import 'src/scss/base';

.circle-loader {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 1;
}

.circle-loader div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 32px;
  height: 32px;
  margin: 4px;
  border: 4px solid $color-purple;
  border-radius: 50%;
  animation: circle-loader 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $color-purple transparent transparent transparent;
}

.circle-loader div:nth-child(1) {
  animation-delay: -0.3s;
}

.circle-loader div:nth-child(2) {
  animation-delay: -0.2s;
}

.circle-loader div:nth-child(3) {
  animation-delay: -0.1s;
}

@keyframes circle-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
