@import 'src/scss/base';

.medgate__title {
  @include font-aribau-bold;

  font-size: px2rem(16);
  color: $color-orange;
  margin-top: px2rem(25);
  margin-bottom: px2rem(15);

  @include md {
    font-size: px2rem(18);
    margin-top: px2rem(25);
    margin-bottom: px2rem(20);
  }
}

.medgate__text {
  @include font-aribau-bold;

  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  display: block;
  text-align: left;
  color: $color-black;

  a {
    color: $color-purple;
  }

  ul {
    padding-left: 0;
    margin-left: 0;
  }

  ol {
    padding-left: 0;
    margin-left: 0;
    display: flex;

    div {
      margin-right: px2rem(3);
    }
  }

  @include lg {
    margin-bottom: px2rem(10);
  }
}

.medgate__accordion-text {
  @include font-aribau;

  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  display: block;
  text-align: left;
  color: $color-dark-grey;
  padding-top: px2rem(10);

  ul {
    padding: 0;
    padding-top: px2rem(10);
    margin: 0;
  }

  ol {
    padding-left: 0;
    margin-left: 0;
    display: flex;

    div {
      margin-right: px2rem(3);
    }
  }
}

.medgate__accordion {
  .accordion__title {
    font-size: px2rem(15);
  }

  .accordion__content-wrapper {
    padding: px2rem(15) 0;
  }
}

.medgate__accordion-content {
  padding-top: 0;
}

.medgate__button {
  display: inline-block;
  margin: px2rem(15) auto 0;

  .no-touchevents &:hover {
    border-color: $color-orange;
  }
}

.medgate__accordion-wrapper {
  @include lg {
    width: 70%;
  }
}
