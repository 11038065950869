@import 'src/scss/base';

.recommendation-card {
  @include card-shadow;
  margin: px2rem(22) px2rem(10);
  border-radius: px2rem(8);
  background-color: $color-white;
  position: relative;
  min-height: px2rem(296);

  @include md {
    width: 100%;
  }

  @include lg {
    flex-basis: 31%;
    margin: 0;
    min-height: px2rem(355);
  }

  &:last-child {
    @include lg {
      margin-right: px2rem(0);
    }
  }
}

.recommendation-card--two-in-row {
  @include lg {
    margin-right: px2rem(30);
  }
}

.recommendation-card--four-in-row {
  @include lg {
    flex-basis: 23%;
  }
}

.recommendation-card__button {
  @include button-default;
  padding: px2rem(15);
  width: 100%;
  height: 100%;

  @include md {
    padding: px2rem(25);
  }

  @include lg {
    padding: px2rem(20) px2rem(15);
  }
}

.recommendation-card__icon-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: px2rem(105);
  height: px2rem(105);
  position: relative;
  margin: 0 auto;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @include lg {
    width: px2rem(125);
    height: px2rem(125);
  }
}

.recommendation-card__icon {
  position: absolute;
  z-index: 1;
  visibility: hidden;
}

.recommendation-card__icon--visible {
  visibility: visible;
}

.recommendation-card__price-wrapper {
  display: block;
  position: absolute;
  top: px2rem(15);
  right: px2rem(-28);
  z-index: 1;

  @include lg {
    top: 0;
    right: px2rem(-30);
  }
}

.recommendation-card__title {
  @include font-aribau-bold;
  color: $color-purple;
  font-size: px2rem(16);
  line-height: px2lineheight(16, 21);
  display: block;
  margin-top: px2rem(10);
  text-align: center;

  .checked & {
    color: $color-orange;
  }

  @include lg {
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
    margin-top: px2rem(5);
  }
}

.recommendation-card__subtitle {
  @include font-aribau;
  color: $color-black;
  font-size: px2rem(11);
  line-height: px2lineheight(11, 14);
  display: block;
  margin-top: px2rem(10);
  text-align: left;

  @include md {
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
    margin-top: px2rem(15);
  }
}

.recommendation-card__products {
  @include list-reset;
  @include font-aribau;
  text-align: left;
  color: $color-black;
  font-size: px2rem(11);
  line-height: px2lineheight(11, 14);
  display: block;
  margin: px2rem(8) 0 px2rem(20);
  position: relative;
  padding-left: px2rem(10);

  @include lg {
    padding-left: 0;
    margin-top: px2rem(2);
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
    margin: px2rem(10) 0 px2rem(10);
  }
}

.recommendation-card__product {
  display: block;
  position: relative;
  padding-left: px2rem(10);

  &:not(:last-child) {
    margin-bottom: px2rem(5);
  }

  &:before {
    content: '';
    width: px2rem(4);
    height: px2rem(4);
    background: $color-black;
    border-radius: 50%;
    position: absolute;
    top: calc(50% - 2px);
    left: 0;
  }

  @include lg {
    padding-left: px2rem(18);

    &:before {
      width: px2rem(8);
      height: px2rem(8);
      top: px2rem(7);
    }
  }
}

.recommendation-card__wrapper {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
}

// Markdown
.recommendation-card__text-wrapper {
  padding: px2rem(25);

  p {
    @include font-aribau;
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
    color: $color-black;
  }

  strong {
    @include font-aribau-bold;
    color: $color-black;
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);

    @include md {
      font-size: px2rem(20);
      line-height: px2lineheight(20, 26);
    }
  }

  ul {
    @include list-reset;
    margin: px2rem(15) 0 px2rem(25);
  }

  li {
    @include font-aribau-bold;
    color: $color-purple;
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
    display: flex;
    align-items: center;
    margin-bottom: px2rem(10);

    &:before {
      margin-right: px2rem(10);
      content: '';
      display: block;
      border-radius: 50%;
      width: px2rem(6);
      height: px2rem(6);
      background-color: $color-purple;
    }

    @include md {
      font-size: px2rem(14);
      line-height: px2lineheight(14, 18);
      margin-bottom: px2rem(18);

      &:before {
        width: px2rem(8);
        height: px2rem(8);
        margin-right: px2rem(15);
      }
    }
  }

  @include lg {
    padding: px2rem(35) px2rem(25);
  }
}

.recommendation-card__text {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);
  color: $color-black;
  margin-bottom: px2rem(5);

  @include lg {
    margin-bottom: px2rem(10);
  }
}

.recommendation-card__info-button {
  @include button-default;
  position: absolute;
  width: px2rem(22);
  height: px2rem(22);
  display: none;
}

.recommendation-card__info-icon {
  font-size: px2rem(18) !important;
  fill: $color-disabled !important;
}

.recommendation-card__button-wrapper {
  display: flex;
  justify-content: space-between;
}

.recommendation-card__button-wrapper--one-button {
  justify-content: flex-end;
}

.recommendation-card__read-more-button,
.recommendation-card__select-button {
  min-width: px2rem(74);
  height: px2rem(30);
  font-size: px2rem(10);
  line-height: px2lineheight(10, 13);
  padding: 0 px2rem(5);

  @include md {
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
    min-width: px2rem(100);
    height: px2rem(30);
  }

  @include lg {
    min-width: px2rem(85);
    height: px2rem(30);
  }
}

.recommendation-card__read-more-button {
  @include font-aribau;
}

.recommendation-card__select-button {
  @include font-aribau-bold;
  border-color: $color-purple;

  .no-touchevents &:hover {
    color: $color-white;
    background: $color-orange;
    border-color: $color-orange;
  }

  @include lg {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}

.recommendation-card__select-button--selected {
  color: $color-white;
  background: $color-orange;
  border-color: $color-orange;
}

.recommendation-card__badge {
  @include font-aribau-bold;
  font-size: px2rem(10);
  line-height: px2lineheight(10, 13);
  height: px2rem(20);
  width: px2rem(90);
  color: $color-purple;
  border: px2rem(1) solid $color-purple;
  border-radius: px2rem(5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  padding: 0 px2rem(10);
  top: px2rem(-10);
  background-color: $color-white;
  left: calc(50% - 45px);

  @include md {
    font-size: px2rem(12);
    line-height: px2lineheight(12, 16);
    height: px2rem(23);
    width: px2rem(110);
    left: calc(50% - 55px);
  }
}

.recommendation-card__text {
  @include lg {
    padding: 0 px2rem(15);
  }
}
