@import 'src/scss/base';

.text {
  position: absolute;
  right: $spacing-24;
  padding: $spacing-8;
  border-radius: $spacing-16;
  background: $color-secondary;
  color: $color-white;
  display: flex;
  align-items: center;
}
