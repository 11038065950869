@import 'src/scss/base';

.select-indicator {
  width: px2rem(17);
  height: px2rem(17);
  border: px2rem(2) solid $color-disabled;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  @include lg {
    width: px2rem(38);
    height: px2rem(38);
  }

  &.isSquare {
    border-radius: 5px;

    .checked & {
      border-color: $color-orange;
    }
  }
}

.select-indicator__inner {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $color-orange;
  width: px2rem(9);
  height: px2rem(9);
  border-radius: 50%;

  svg {
    fill: $color-white;
    font-size: px2rem(15);
  }

  @include lg {
    width: px2rem(26);
    height: px2rem(26);

    svg {
      font-size: px2rem(35);
    }
  }

  .isSquare & {
    border-radius: 0;
    width: 100%;
    height: 100%;

    @include lg {
      width: 100%;
      height: 100%;
    }
  }
}
