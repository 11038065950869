@import 'src/scss/base';

.wrapper {
  border-radius: $spacing-8;
  padding: $spacing-24;

  @include md {
    padding: $spacing-32;
  }
}

.text {
  margin-bottom: $spacing-8;
}
