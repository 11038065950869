@import 'src/scss/base';

.order-test-modal-content-completed {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(20deg, #554494 -8.14%, #ff6145 103.42%);
  color: $color-white;
  padding: 253px $spacing-32 141px $spacing-32;

  @include md {
    height: auto;
    border-radius: 28px;
    padding: 226px $spacing-48 138px $spacing-48;
  }
}

.order-test-modal-content-completed__text-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-24;
  margin-bottom: $spacing-40;
}

.order-test-modal-content-completed__description {
  text-align: center;
}

.order-test-modal-content-completed__close-button {
  @include button-default;
  position: absolute;
  top: $spacing-32;
  right: $spacing-24;
  display: flex;
  align-items: center;

  & svg,
  path {
    fill: $color-white;
    width: 100%;
  }
}
