@import 'src/scss/base';

.create-password-form__buttons-wrapper {
  display: flex;
  justify-content: space-between;

  &--error {
    margin-top: px2rem(75);

    @include lg {
      margin-top: px2rem(80);
    }
  }
}

.create-password-form__back-button {
  min-width: px2rem(100);
  
  svg {
    margin-right: 0;
    margin-left: unset;
  }
}