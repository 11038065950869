@import 'src/scss/base';

.price-tag {
  @include font-aribau;
  position: absolute;
  top: px2rem(5);
  right: px2rem(5);
  display: flex;
  width: px2rem(45);
  height: px2rem(45);
  border-radius: 50%;
  background-color: $color-orange;
  color: $color-white;
  align-items: center;
  justify-content: center;
  text-align: center;
  z-index: 2;

  @include md {
    top: px2rem(12);
    right: px2rem(12);
  }

  @include lg {
    width: px2rem(78);
    height: px2rem(78);
  }
}

.price-tag__small-text {
  font-size: px2rem(8);

  @include lg {
    font-size: px2rem(11);
  }
}

.price-tag__big-text {
  font-size: px2rem(10);

  @include lg {
    font-size: px2rem(14);
  }
}
