@import 'src/scss/base';

.status {
  margin-bottom: $spacing-16;
}

.title {
  margin-bottom: $spacing-8;
}

.text {
  margin-bottom: $spacing-40;

  @include lg {
    @include body-1;
  }
}

.infoWrapper {
  display: flex;
  flex-direction: column;
}

.buttonWrapper {
  color: $color-grey-darker;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid $color-grey-light;
  padding: $spacing-24 0;
}

.buttonTextWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}

.buttonText {
  @include lg {
    @include body-1;
  }
}

.button {
  @include button-default;
  width: 100%;
  border-top: 1px solid $color-grey-light;
  padding: $spacing-24 0;
}
