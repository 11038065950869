@import 'src/scss/base';

.risk-modal-card {
  height: auto;
  width: 100%;
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  margin: px2rem(50) px2rem(10) px2rem(20);
  position: relative;

  @include md {
    margin: px2rem(50) px2rem(15) px2rem(20);
  }

  @include lg {
    margin: px2rem(30) px2rem(15) px2rem(20);
  }
}

.risk-modal-card__image-wrapper {
  width: px2rem(90);
  height: px2rem(90);
  margin: 0 auto;

  img {
    width: 100%;
  }

  @include md {
    width: px2rem(150);
    height: px2rem(150);
  }
}

.risk-modal-card__block {
  background-color: $color-light-grey-2;
  padding: px2rem(10) px2rem(20) px2rem(23);

  @include lg {
    padding: px2rem(12) px2rem(22) px2rem(28);
  }
}

.risk-modal-card__title {
  @include font-aribau-bold;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
  margin: px2rem(5) 0 0;
  text-align: center;
  display: block;
  color: $color-orange;

  @include md {
    margin-bottom: px2rem(10);
  }
}

.risk-modal-card__text {
  @include font-aribau;
  color: $color-black;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);

  @include md {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}

.risk-modal-card__list {
  @include list-reset;
  margin-top: px2rem(6);
}

.risk-modal-card__list-item {
  @include font-aribau-bold;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 18);
  color: $color-orange;
  display: flex;
  align-items: center;

  &:before {
    content: '';
    width: px2rem(5);
    height: px2rem(5);
    border-radius: 50%;
    background: $color-orange;
    display: block;
    margin-right: px2rem(6);
  }

  @include md {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 24);

    &:before {
      width: px2rem(8);
      height: px2rem(8);
    }
  }
}

.risk-modal-card__top-block {
  padding: px2rem(15) 0 px2rem(15);
  background-color: $color-white;
  border-top-left-radius: px2rem(8);
  border-top-right-radius: px2rem(8);
  text-align: center;

  @include md {
    padding: px2rem(20) 0;
  }
}

.risk-modal-card__block-text {
  &:not(:first-child) {
    margin-top: px2rem(14);
  }

  @include lg {
    &:not(:first-child) {
      margin-top: px2rem(24);
    }
  }
}

.risk-modal-card__bottom-block {
  color: $color-black;
  background-color: $color-white;
  border-bottom-left-radius: px2rem(8);
  border-bottom-right-radius: px2rem(8);
  padding: px2rem(22) px2rem(18);

  @include lg {
    padding: px2rem(24) px2rem(22) px2rem(30);
  }
}

.risk-modal-card__block-title {
  @include font-aribau-bold;
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  display: block;
  margin-bottom: px2rem(6);

  @include lg {
    margin-bottom: px2rem(8);
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
  }
}

.risk-modal-card__block-title--score {
  margin-bottom: px2rem(16);

  @include lg {
    margin-bottom: px2rem(20);
  }
}

.risk-modal-card__read-more {
  @include font-aribau;
  color: $color-purple;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);

  @include md {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
  }
}
