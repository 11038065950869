@import 'src/scss/base';

.forgot-password-form__buttons-wrapper {
  display: flex;
  justify-content: space-between;
}

.forgot-password-form__back-button {
  min-width: px2rem(100);
  
  svg {
    margin-right: 0;
    margin-left: unset;
  }
}
