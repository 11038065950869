@import 'src/scss/base';

.form-button {
  display: block;
  height: px2rem(45);

  &--back {
    padding-left: 0;
  }

  svg {
    margin-left: 0;
  }

  .no-touchevents &:hover {
    border-color: $color-orange;
    border-width: px2rem(2);
  }

  .no-touchevents &--back:hover {
    border-color: $color-purple;
  }
}

.form-button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}