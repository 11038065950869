@import 'src/scss/base';

.wrapper {
  color: $color-blacky;
  display: flex;
  flex-direction: column;
  gap: $spacing-24;

  @include md {
    gap: $spacing-24;
  }
}

.title {
  text-align: start;
}

.buttonWrapper {
  display: flex;
  align-items: center;
  gap: $spacing-8;
}
