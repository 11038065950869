@import 'src/scss/base';

.phone-code-form__input-wrapper {
  display: flex;
  justify-content: space-between;

  & > div {
    width: px2rem(40);
  }

  @include lg {
    & > div {
      width: px2rem(48);
    }
  }
}

.phone-code-form__input {
  @include font-aribau;
  font-size: px2rem(22);
  text-align: center;
  width: px2rem(48) !important;
  border: 0;
  background: 0;
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: px2rem(5);
  color: $color-black;

  &::placeholder {
    color: #848484;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

.phone-code__text {
  @include font-aribau;
  font-size: px2rem(14);
  color: #848484;
  text-align: center;
  margin-top: px2rem(25);
}

.phone-code__button {
  @include font-aribau-bold;
  font-size: px2rem(14);
  color: $color-purple;
  background: none;
  border: none;

  .no-touchevents & {
    cursor: pointer;
  }

  .no-touchevents &:hover {
    text-decoration: underline;
  }

  &:disabled {
    color: $color-dark-grey;
  }

  .no-touchevents &:disabled {
    cursor: not-allowed;
  }

  &:disabled:hover {
    text-decoration: none;
  }
}

.phone-code__buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: px2rem(30);

  &--hasBackButton {
    justify-content: space-between;
  }
}

.phone-code__back-button {
  min-width: px2rem(100);

  svg {
    margin-right: 0;
    margin-left: unset;
  }
}

.phone-code__finish-button {
  min-width: px2rem(110);

  svg {
    margin-right: px2rem(-20);
    margin-left: 0;
  }
}

.phone-code__error {
  @include font-aribau;
  font-size: px2rem(14);
  color: $color-red;
  display: block;
  margin-top: px2rem(5);
}
