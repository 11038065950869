@import 'src/scss/base';

.wrapper {
  color: $color-blacky;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.badgeTextWrapper {
  margin-left: $spacing-8;
  color: $color-white;
  border-radius: 5px;
}

.badgeText {
  padding: $spacing-4 10px;

  &::first-letter {
    text-transform: capitalize;
  }
}
