@import 'src/scss/base';

.home--allergy-check-recommendation {
  background-color: $color-grey-light;

  .content {
    background-color: $color-grey-light;
  }

  .header {
    background-color: $color-grey-light;
  }

  .header__content {
    padding: 0 $spacing-8;
  }

  .header__icons-wrapper a svg {
    fill: $color-blacky;
  }

  .header__logo-wrapper {
    width: auto;
    height: auto;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .dropdown__wrapper {
    border: 1px solid $color-grey-lighter;
    padding: 6px 12px;
    width: fit-content;
    gap: 4px;
  }

  .dropdown {
    color: $color-blacky;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.25px;
    height: auto;
    margin-right: $spacing-8;
  }

  .dropdown__icon {
    fill: $color-blacky;
  }

  .header__right-buttons {
    padding-right: 12px;
  }

  .header__close-button {
    width: 18px;
    height: 18px;
    margin-left: $spacing-8;
  }

  .header__close-button svg {
    fill: $color-blacky;
    width: 100%;
    height: 100%;
  }

  .progress-bar {
    background-color: $color-grey-light;
    margin-bottom: $spacing-48;
  }

  .progress-bar__content {
    background-color: #0000000d;
    max-width: 100%;
  }

  .progress-bar__title {
    display: none;
  }

  .progress-bar__steps {
    display: none;
  }

  .progress-bar__bar-wrapper {
    height: 5px;
    background-color: #0000000d;
  }

  .progress-bar__bar {
    background: $color-gradient;
    height: 5px;
    border-radius: 32px;
  }

  .page-title__text {
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -1px;
    color: $color-blacky;
    padding: 0;
    margin-bottom: $spacing-32;
  }

  .text-block {
    padding-top: 0;
    padding-bottom: px2rem(80);
  }

  .text-block__content {
    box-shadow: none;
    border-radius: 32px;
    padding: $spacing-24;
    margin: 0;
  }

  .text-block__image-wrapper {
    width: 100%;
    margin-right: 0;
    float: none;
    margin-bottom: $spacing-24;
    border-radius: 32px;
  }

  .text-block__text {
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
    color: $color-blacky;
  }

  .actions-bar {
    background: $color-grey-light;
    background: linear-gradient(
      to bottom,
      rgba(241, 241, 241, 0) 0%,
      rgb(241, 241, 241) 35%,
      rgb(241, 241, 241) 100%
    );
  }

  .button {
    padding: 0 $spacing-32;
    height: auto;
    border: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    letter-spacing: 0.25px;
    text-align: center;
    min-width: 150px;
    min-height: 50px;
  }

  .button--orange {
    background: $color-gradient;
  }

  .button--orange:hover {
    color: $color-white;
  }

  .button--orange:hover svg {
    fill: $color-white;
  }

  .button--purple svg {
    fill: $color-secondary;
  }

  .button--purple:hover {
    background-color: transparent;
  }

  .actions-bar__button--back {
    color: $color-secondary;
    min-width: 70px;
    background-color: transparent;
    border: 1px solid $color-secondary;

    .loader div {
      background: $color-secondary;
    }
  }

  .checkboxes-question {
    padding: 0;
  }

  .checkboxes-question__content {
    box-shadow: none;
    border-radius: 32px;
    padding: $spacing-24;
    margin: 0;
    color: $color-blacky;
    font-size: 16px;
    font-weight: 300;
    line-height: 130%;
  }

  .checkbox__label {
    align-items: flex-start;
  }

  .checkbox__input {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    margin-right: $spacing-8;
    border: 0.15em solid $color-grey;
    margin-top: 2px;
  }

  .checkbox__input--radio {
    border-radius: 50%;
  }

  .checkbox__input:checked {
    background: $color-secondary;
    border: none;
  }

  .checkbox__checkmark {
    left: 7px;
    width: 0.33rem;
    height: 0.7rem;
    top: 4px;
  }

  .checkbox__label--disabled {
    opacity: 0.5;
  }

  .checkboxes-question__checkbox {
    margin-bottom: px2rem(16);
  }

  .checkboxes-question__checkbox--none {
    font-weight: 300;
  }

  .checkboxes-question__checkbox:last-child {
    margin-bottom: 0;
  }

  .checkboxes-question__checkbox--none {
    border: none;
    padding-top: 0;
  }

  .page-title--sticky {
    position: relative;
  }

  .actions-bar__content {
    padding: px2rem(30) px2rem(12) px2rem(30);
    justify-content: flex-end;
  }

  .actions-bar__button--back {
    margin-right: auto;
  }

  .text-block__text li {
    padding-left: $spacing-24;
  }

  .text-block__text li::before {
    content: url('../../../assets/svg/green-check.svg');
    width: px2rem(18);
    height: px2rem(18);
    left: 0;
    top: px2rem(2);
    background: transparent;
  }

  .page-title__text strong,
  .text-block__text strong {
    font-weight: 400;
    background: linear-gradient(90deg, #594695, #955078);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

@include md {
  .home--allergy-check-recommendation {
    .header {
      padding: 0 20px;
    }

    .header__reset-button--large {
      padding: 0;
      height: 100%;

      .button__content {
        justify-content: unset;
        color: $color-blacky;
      }
    }

    .header__revert-icon {
      fill: $color-blacky;
    }

    .progress-bar {
      padding: 0 20px;
      max-width: 1200px;
    }

    .page-title__text {
      text-align: center;
      font-size: 32px;
      padding: 0 20px;
    }

    .text-block {
      padding: 0 20px;
    }

    .text-block__content {
      margin: 0 auto;
    }

    .text-block__text {
      font-size: 20px;
    }

    .checkboxes-question {
      padding: 0 20px;
    }

    .checkboxes-question__content {
      margin: 0 auto;
      font-size: 20px;
      padding: $spacing-32;
    }

    .actions-bar__content {
      padding: 20px 20px 30px;
    }

    .checkbox__input {
      width: 24px;
      height: 24px;
      margin-top: 0;
    }

    .checkbox__checkmark {
      left: 0.6rem;
      width: 0.33rem;
      height: 0.7rem;
      top: 4px;
    }
  }
}

@include lg {
  .home--allergy-check-recommendation {
    .header {
      max-width: 1200px;
      margin: 0 auto;
      padding: 0;
    }

    .header__content {
      max-width: 100%;
      padding: 0 $spacing-16;
      margin: 0;
    }

    .header__reset-button--large {
      .button__content:hover svg {
        fill: $color-blacky;
      }
    }

    .header__reset-button--large:hover {
      background-color: transparent;
    }

    .header__close-button {
      top: 41px;
      right: 16px;
    }

    .dropdown__wrapper {
      margin-right: $spacing-32;
    }

    .page-title__text {
      margin-bottom: $spacing-40;
    }

    .checkboxes-question__content {
      padding: $spacing-64;
    }

    .checkbox__input {
      margin-right: $spacing-16;
    }

    .checkbox__label {
      align-items: center;
    }

    .checkbox__checkmark {
      top: 5px;
    }

    .text-block__content {
      padding: $spacing-24 $spacing-56 $spacing-24 $spacing-24;
    }

    .text-block__text {
      margin-top: $spacing-16;
    }

    .text-block__image-wrapper {
      width: 50%;
      float: left;
      margin-bottom: 0;
    }

    .text-block__text-wrapper--no-image .text-block__text {
      margin-top: 0;
    }

    .progress-bar {
      margin: 0 auto;
      margin-bottom: $spacing-80;
      padding: 0;
    }

    .actions-bar__right-button-wrapper {
      margin-left: $spacing-24;
      cursor: pointer;
    }

    .text-block__image-wrapper {
      display: flex;
      align-items: center;
    }
  }
}
