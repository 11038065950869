@import 'src/scss/base';

.answer-card {
  height: auto;
  flex-basis: 46%;
  width: 46%;
  border-radius: 8px;
  background-color: $color-white;
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.4);
  margin-bottom: px2rem(15);
  position: relative;
  min-height: px2rem(132);
  // delete after translation come
  overflow: hidden;

  &:nth-child(even) {
    margin-left: px2rem(17);
  }

  @include md {
    min-height: px2rem(200);
  }

  @include lg {
    min-height: px2rem(310);
    margin-bottom: px2rem(30);
    flex-basis: 31%;

    &:nth-child(even) {
      margin-left: px2rem(0);
    }
  }
}

.answer-card__wrapper {
  @include button-default;
  width: 100%;
  height: 100%;
  min-height: inherit;
}

.answer-card__content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: px2rem(15) 0 px2rem(12);
  min-height: inherit;

  @include md {
    padding: px2rem(30) 0;
  }

  @include lg {
    padding: px2rem(25) 0;
  }
}

.answer-card__content--has-tag {
  padding-top: px2rem(20);
}

.answer-card__icon-wrapper {
  display: block;
  width: px2rem(65);
  height: px2rem(65);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: px2rem(4);
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
  }

  @include md {
    width: px2rem(85);
    height: px2rem(85);
  }

  @include lg {
    width: px2rem(160);
    height: px2rem(160);
    margin-bottom: px2rem(18);
  }
}

.answer-card__icon-wrapper--no-icon {
  width: 0;
  height: 0;
}

.answer-card__icon {
  position: absolute;
  z-index: 1;
  visibility: hidden;
}

.answer-card__icon--visible {
  visibility: visible;
}

.answer-card__title {
  display: block;
  margin-bottom: px2rem(4);
  color: $color-black;
  font-family: 'Aribau Grotesk';
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  position: relative;
  padding: 0 px2rem(10);

  .checked & {
    color: $color-orange;
    font-weight: 700;
  }

  @include md {
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
    margin-bottom: px2rem(10);
    padding: 0 px2rem(15);
  }

  @include lg {
    margin-bottom: px2rem(18);
  }
}

.answer-card__info {
  @include button-default;
  width: px2rem(30);
  height: px2rem(30);
  position: absolute;
  right: px2rem(0);
  top: px2rem(0);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;

  @include md {
    width: px2rem(35);
    height: px2rem(35);
  }

  @include lg {
    width: px2rem(40);
    height: px2rem(40);
  }
}

.answer-card__info-icon {
  font-size: px2rem(20) !important;
  fill: $color-orange !important;
  margin-right: px2rem(2);
  margin-top: px2rem(2);

  @include md {
    font-size: px2rem(22) !important;
  }

  @include lg {
    font-size: px2rem(27) !important;
  }
}

.answer-card__modal-content {
  padding-top: px2rem(55);
  text-align: center;

  @include md {
    padding-top: px2rem(40);
    text-align: left;
  }
}

.answer-card__modal-title {
  @include font-aribau;
  color: $color-black;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
  display: block;
  margin-top: px2rem(15);
  margin: px2rem(10) 0 px2rem(22);
  text-align: center;

  @include md {
    margin: px2rem(15) 0 px2rem(45);
  }
}

.answer-card__modal-image {
  width: px2rem(105);
  height: px2rem(105);
  margin: 0 auto;

  img {
    width: 100%;
  }

  @include md {
    width: px2rem(150);
    height: px2rem(150);
  }
}

.asnwer-card__modal-description {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);
  color: $color-black;
  display: inline-block;
  margin: 0 auto;

  ul {
    @include list-reset;
    margin-left: px2rem(10);
  }

  li {
    @include font-aribau-bold;
    color: $color-purple;
    display: block;
    text-align: left;
    position: relative;
    padding-left: px2rem(12);
    margin-bottom: px2rem(15);

    &:before {
      content: '';
      width: px2rem(7);
      height: px2rem(7);
      background-color: $color-purple;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: px2rem(5);
    }
  }

  p {
    margin-bottom: px2rem(15);
  }

  @include md {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
    margin: unset;

    li {
      padding-left: px2rem(15);
      margin-bottom: px2rem(20);

      &:before {
        width: px2rem(7);
        height: px2rem(7);
        top: px2rem(5);
      }
    }
  }
}

.asnwer-card--in-carousel {
  @include lg {
    &:not(:nth-child(3n)) {
      margin-right: 30px;
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
