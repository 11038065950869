@import 'src/scss/base';

.button {
  @include font-aribau-bold;
  @include button-default;
  height: px2rem(38);
  border: 1px solid $color-purple;
  border-radius: 50px;
  padding: 0 px2rem(20) 0 px2rem(15);
  font-size: px2rem(14);
  color: $color-purple;
  line-height: px2lineheight(14, 16);
  min-width: px2rem(110);
  overflow: hidden;

  svg {
    margin-right: px2rem(10);
  }

  .no-touchevents &:hover {
    color: $color-white;
    background-color: $color-purple;

    svg {
      fill: $color-white;
    }
  }

  @include lg {
    min-width: px2rem(130);
    height: px2rem(45);
  }
}

.button--icon-right {
  svg {
    margin-right: px2rem(-10);
    margin-left: px2rem(10);
  }
}

.button--orange {
  border-color: $color-orange;
  background-color: $color-orange;
  color: $color-white;

  svg {
    fill: $color-white;
  }

  &.disabled,
  &:disabled,
  &.button--disabled-link,
  &.button--uploading {
    background-color: $color-disabled;
    border-color: $color-disabled;
    cursor: not-allowed;
    color: $color-white;
    pointer-events: none;

    svg {
      fill: $color-white;
    }

    .no-touchevents &:hover {
      background-color: $color-disabled;
      border-color: $color-disabled;
      cursor: not-allowed;
      color: $color-white;

      svg {
        fill: $color-white;
      }
    }
  }

  .no-touchevents &:hover {
    border-color: $color-white;
    background-color: $color-white;
    color: $color-orange;

    svg {
      fill: $color-orange;
    }
  }

  .no-touchevents &:disabled,
  .no-touchevents &.disabled {
    background-color: $color-disabled;
    border-color: $color-disabled;
    cursor: not-allowed;
    color: $color-white;
    pointer-events: none;

    svg {
      fill: $color-white;
    }
  }
}

.button--purple {
  border-color: $color-white;
  background-color: $color-purple;
  color: $color-white;

  svg {
    fill: $color-white;
  }

  &.button--disabled-link,
  &.disabled {
    background-color: $color-disabled;
    border-color: $color-disabled;
    color: $color-white;
    cursor: not-allowed;
    pointer-events: none;
  }

  .no-touchevents &:hover {
    color: $color-purple;
    background-color: $color-white;

    svg {
      fill: $color-purple;
    }
  }
}

.button__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
