@import 'src/scss/base';

.slide {
  height: auto;
  opacity: 0.3;
}

.slide__title {
  @include font-aribau-bold;

  font-size: px2rem(14);
  color: $color-purple;
  margin-top: px2rem(5);
  margin-bottom: px2rem(8);
  display: block;

  @include md {
    font-size: px2rem(16);
  }
}

.slide__text {
  @include font-aribau;

  font-size: px2rem(12);
  color: $color-dark-grey;

  ul {
    @include list-reset;
  }

  ul li {
    padding-left: px2rem(15);
    margin-bottom: px2rem(5);
    display: block;
    position: relative;

    &:before {
      content: '';
      border-radius: 50%;
      width: px2rem(6);
      height: px2rem(6);
      background: $color-purple;
      position: absolute;
      left: px2rem(0);
      top: px2rem(5);
    }
  }

  @include md {
    font-size: px2rem(14);
  }
}

.slide__image {
  overflow: hidden;
  position: relative;

  @media only screen and (min-width: 0px) and (max-width: 374px) {
    width: 200px !important;
    height: 355px !important;
  }

  @media only screen and (min-width: 375px) and (max-width: 449px) {
    width: 250px !important;
    height: 445px !important;
  }

  @media only screen and (min-width: 450px) and (max-width: 599px) {
    width: 320px !important;
    height: 570px !important;
  }

  @include md {
    width: unset;
  }
}

.slide__index {
  @include font-aribau;
  font-size: px2rem(12);
  color: $color-orange;
  position: absolute;
  top: px2rem(10);
  left: px2rem(10);
  border-radius: 50%;
  background: $color-white;
  width: px2rem(20);
  height: px2rem(20);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  @include md {
    font-size: px2rem(16);
    width: px2rem(26);
    height: px2rem(26);
  }
}

.slide__loader {
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: calc(50% - 60px);
}

.slide .slide__image img {
  width: 100%;
  display: block;
}

.slide--next {
  opacity: 0.6;
}

.slide--prev {
  opacity: 0.6;
}

.slide--active {
  opacity: 1;
}

.slide__text {
  display: block;
}

.slide__video {
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  display: none;
}

.slide__video--visible {
  z-index: 1;
  position: relative;
  display: block;
}

.slide__video--hidden {
  display: none !important;
}

.slide__wrapper {
  position: relative;
}

.slide__wrapper--loading {
  opacity: 0.6;
}
