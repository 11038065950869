@import 'src/scss/base';

.navbar-bottom {
  padding: $spacing-16 0;
  background: $color-white;
  position: fixed;
  width: 100%;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: space-around;

  @include lg {
    display: none;
  }
}

.navbar-bottom__link {
  @include button-default;
  color: $color-blacky;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $spacing-8;
  position: relative;

  & svg {
    width: $spacing-24;
    height: $spacing-24;
  }
}

.navbar-bottom__active-link {
  background: $color-gradient;
  -webkit-text-fill-color: transparent;
  -webkit-background-clip: text;

  svg path {
    stroke: url(#linear_gradient);
  }
}

.navbar-bottom__active-link .navbar-bottom__heart-icon {
  path {
    stroke: none;
    fill: url(#linear_gradient);
  }
}

.navbar-bottom__bubble {
  width: $spacing-16;
  height: $spacing-16;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: $color-gradient;
  -webkit-text-fill-color: $color-white;
  position: absolute;
  right: -12px;
  bottom: 12px;
}

.navbar-bottom__number-of-unviewed-results {
  font-size: 10px;
  font-weight: 400px;
}
