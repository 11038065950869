@import 'src/scss/base';

.my-health-container {
  width: 100%;
}

.my-health-container__loader {
  display: flex;
  justify-content: center;
  padding-top: 100px;
}
