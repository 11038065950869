@import 'src/scss/base';

.wrapper {
  padding: $spacing-16 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-top: 1px dashed $color-grey;
}

.title {
  margin-bottom: $spacing-8;
}

.description {
  margin-bottom: $spacing-16;
}
