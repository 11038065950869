@import 'src/scss/base';

.dropdown__wrapper {
  width: px2rem(40);
  height: px2rem(30);
  border: 1px solid $color-purple;
  position: relative;
  z-index: 10;
  border-radius: px2rem(5);
  display: flex;
  align-items: center;
  padding: 0 px2rem(2);

  @include lg {
    padding: 0 px2rem(4);
    height: px2rem(34);
    width: px2rem(48);
  }
}

.dropdown {
  @include button-default;
  @include font-aribau-bold;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  line-height: px2lineheight(12, 14);
  font-size: px2rem(12);
  color: $color-purple;
  width: 100%;
  height: 100%;
  z-index: 1;

  @include lg {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 16);
  }
}

.dropdown__icon {
  position: absolute;
  width: px2rem(12);
  fill: $color-purple;
  position: absolute;
  right: px2rem(3);
}
