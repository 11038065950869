@import 'src/scss/base';

.wrapper {
  padding: $spacing-16 0;
  display: flex;
  gap: $spacing-16;
  border-top: 1px dashed $color-grey;
}

.text {
  margin-bottom: $spacing-8;
}

.description {
  margin-bottom: $spacing-16;
}

.imageWrapper {
  min-width: 85px;
  width: 85px;
  display: flex;
  align-items: flex-start;

  @include md {
    min-width: 105px;
    width: 105px;
  }
}

.image {
  width: 100%;
}

.copyButton {
  margin-top: $spacing-16;
  align-self: flex-start;

  @include md {
    margin-top: 0;
    align-self: unset;
  }
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;

  @include md {
    flex-direction: row;
    display: flex;
    align-items: center;
    gap: $spacing-16;
  }

  @include md {
    gap: $spacing-24;
  }
}
