@import 'src/scss/base';

.checkboxes-question {
  padding: px2rem(15) 0 px2rem(60);

  @include md {
    padding-bottom: px2rem(15);
  }
}

.checkboxes-question__content {
  @include font-aribau;
  margin: 0 px2rem(10);
  padding: px2rem(28) px2rem(28) px2rem(35);
  border-radius: px2rem(8);
  background-color: $color-white;
  box-shadow: 0 px2rem(2) px2rem(15) 0 rgba(0, 0, 0, 0.4);
  font-size: px2rem(14);
  line-height: px2lineheight(14, 18);
  color: $color-dark-grey;
  max-width: px2rem(920);
  text-align: center;
  display: flex;
  flex-direction: column;

  @include md {
    margin: 0 px2rem(30);
    font-size: px2rem(16);
    line-height: px2lineheight(16, 21);
    padding: px2rem(35);
  }

  @include lg {
    margin: 0 auto;
    font-size: px2rem(18);
    line-height: px2lineheight(18, 23);
    padding: px2rem(45) px2rem(40);
  }
}

.checkboxes-question__options {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.checkboxes-question__checkbox {
  margin-bottom: px2rem(10);
}

.checkboxes-question__checkbox--none {
  padding-top: px2rem(10);
  font-weight: bold; 
  border-top: 1px solid $color-dark-grey;
}

.checkboxes-question__description-wrapper {
  @include font-aribau;
  text-align: left;

  p {
    margin-bottom: 10px;
  }

  @include md {
    p {
      margin-bottom: 20px;
    }
  }
}

.checkboxes-question__text {
  margin-bottom: px2rem(20);
}