@import 'src/scss/base';

.sign-up {
  position: relative;
  z-index: 1;
  padding: px2rem(25) px2rem(15);

  @include lg {
    margin-top: px2rem(75);
  }
}

.sign-up__background {
  @include lg {
    .background__elipse-wrapper {
      height: 100%;
    }
  }
}