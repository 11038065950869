@import 'src/scss/base';

.page-title {
  position: relative;
}

.page-title--sticky {
  position: fixed;
  top: px2rem(49);
  width: 100%;
  left: 0;
  background-color: $color-white;
  z-index: 10;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.5);

  @include md {
    top: 0;
  }
}

.page-title__text {
  @include font-aribau-bold;
  display: block;
  color: $color-orange;
  font-size: px2rem(18);
  letter-spacing: 0.24px;
  line-height: px2lineheight(18, 23);
  text-align: left;
  padding: px2rem(10);
  max-width: px2rem(920);
  margin: 0 auto;

  p {
    display: inline;
  }

  @include md {
    padding: px2rem(10) px2rem(30);
    font-size: px2rem(22);
    letter-spacing: 0.3px;
    line-height: px2lineheight(22, 28);
  }

  @include lg {
    font-size: px2rem(28);
    line-height: px2lineheight(28, 36);
    letter-spacing: 0.38px;
    padding: px2rem(18) 0 px2rem(5);
  }
}

.page-title__info {
  @include button-default;
  width: px2rem(30);
  height: px2rem(30);
  z-index: 10;
  display: inline;
  justify-content: center;
  align-items: center;

  @include md {
    width: px2rem(35);
    height: px2rem(35);
  }

  @include lg {
    width: px2rem(40);
    height: px2rem(40);
  }
}

.page-title__info-icon {
  font-size: px2rem(20) !important;
  fill: $color-orange !important;
  margin-right: px2rem(2);
  margin-top: px2rem(2);

  @include md {
    font-size: px2rem(22) !important;
  }

  @include lg {
    font-size: px2rem(27) !important;
  }
}

.page-title__modal-content {
  padding-top: px2rem(55);
  text-align: center;

  @include md {
    padding-top: px2rem(50);
    text-align: left;
  }
}

.page-title__modal-title {
  @include font-aribau;
  color: $color-black;
  font-size: px2rem(18);
  line-height: px2lineheight(18, 23);
  display: block;
  margin-top: px2rem(15);
  margin: px2rem(10) 0 px2rem(22);
  text-align: center;

  @include md {
    margin: px2rem(15) 0 px2rem(45);
  }
}

.page-title__modal-description {
  @include font-aribau;
  font-size: px2rem(12);
  line-height: px2lineheight(12, 16);
  color: $color-black;
  display: inline-block;
  margin: 0 auto;

  ul {
    @include list-reset;
    margin-left: px2rem(10);
  }

  li {
    @include font-aribau-bold;
    color: $color-purple;
    display: block;
    text-align: left;
    position: relative;
    padding-left: px2rem(12);
    margin-bottom: px2rem(15);

    &:before {
      content: '';
      width: px2rem(7);
      height: px2rem(7);
      background-color: $color-purple;
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: px2rem(5);
    }
  }

  p {
    margin-bottom: px2rem(15);
  }

  @include md {
    font-size: px2rem(14);
    line-height: px2lineheight(14, 18);
    margin: unset;

    li {
      padding-left: px2rem(15);
      margin-bottom: px2rem(20);

      &:before {
        width: px2rem(7);
        height: px2rem(7);
        top: px2rem(5);
      }
    }
  }
}
