@import 'src/scss/base';

.wrapper {
  padding: $spacing-16 0;
  display: flex;
  gap: $spacing-16;
  border-top: 1px dashed $color-grey;
}

.text {
  margin-bottom: $spacing-8;
}

.imageWrapper {
  @include xs-only {
    width: 70px;
    min-width: 70px;
  }

  width: 85px;
  min-width: 85px;
  display: flex;
  align-items: flex-start;

  @include md {
    width: 105px;
  }
}

.image {
  width: 100%;
}
