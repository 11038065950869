$viewport-sm: 375px;
$viewport-md: 600px;
$viewport-lg: 900px;
// Special brakpoint for header
$viewport-header-lg: 1080px;
$viewport-xl: 1200px;

@mixin xs-only {
  @media (max-width: #{$viewport-sm - 1}) {
    @content;
  }
}

@mixin sm-only {
  @media (max-width: #{$viewport-md - 1}) {
    @content;
  }
}

@mixin sm {
  @media (min-width: #{$viewport-sm}) {
    @content;
  }
}

@mixin md {
  @media (min-width: #{$viewport-md}) {
    @content;
  }
}

@mixin lg {
  @media (min-width: #{$viewport-lg}) {
    @content;
  }
}

@mixin header-lg {
  @media (min-width: #{$viewport-header-lg}) {
    @content;
  }
}

@mixin xl {
  @media (min-width: #{$viewport-xl}) {
    @content;
  }
}
